import React, { Fragment, memo } from "react";

import Separator from "./Separator";
import styled from "styled-components";

const DescriptionText = styled.div`
  background-color: #f7f7f7;
  border-color: #d1d1d1;
  border-radius: 9px;
  border-width: 1px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0px;
  width: 88%;
  color: rgb(134, 134, 134);
  font-family: Gibson-Book;
  font-size: 14px;
  font-weight: 300;

  @media (max-width: 420px) {
    font-size: 1.3em;
  }

  @media (min-width: 420px) and (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.1rem;
  }
`;

const { shouldShow } = {
  shouldShow: (hasModifiers, showCondition) => hasModifiers === showCondition
};

const Description = memo(function Description({
  both,
  description,
  hasModifiers,
  showCondition
}) {
  if ((description && both) || shouldShow(hasModifiers, showCondition)) {
    return (
      <Fragment>
        <Separator showCondition={showCondition} hasModifiaers={hasModifiers} />
        <DescriptionText>{description}</DescriptionText>
      </Fragment>
    );
  }
  return null;
});

export default Description;
