import {
  Container,
  QuantityButton,
  QuantityLabel,
  QuantityText,
  RowContainer
} from "./ItemQuantity.styles";
import React, { memo } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { isLastUnit, subtractQuantity } from "./ItemQuantity.logic";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { incrementSelectedQuantity } from "../../redux/actions";
import { selectedItemQuantitySelector } from "../../redux/selectors";

const plusIsLastUnit = false;

const ItemQuantity = memo(function ItemQuantity({
  quantity,
  incrementSelectedQuantity
}) {
  const onPressMinus = () =>
    subtractQuantity(quantity, incrementSelectedQuantity);
  const onPressPlus = () => incrementSelectedQuantity("add");
  const lastUnit = isLastUnit(quantity);

  return (
    <RowContainer>
      <QuantityLabel>Quantity:</QuantityLabel>
      <Container>
        <TouchableOpacity onPress={onPressMinus}>
          <QuantityButton isLastUnit={lastUnit}>
            <FontAwesomeIcon icon={faMinus} />
          </QuantityButton>
        </TouchableOpacity>
        <QuantityText>{quantity}</QuantityText>
        <TouchableOpacity onPress={onPressPlus}>
          <QuantityButton isLastUnit={plusIsLastUnit}>
            <FontAwesomeIcon icon={faPlus} />
          </QuantityButton>
        </TouchableOpacity>
      </Container>
    </RowContainer>
  );
});

// <IncrementButton isLastUnit={lastUnit} src={minus} />
// <IncrementButton isLastUnit={plusIsLastUnit} src={plus} />

const mapStateToProps = state => ({
  quantity: selectedItemQuantitySelector(state)
});

export default connect(
  mapStateToProps,
  { incrementSelectedQuantity }
)(ItemQuantity);
