import camelCase from "lodash/camelCase";

export const bindActionCreators = (actions, store) => {
  const boundActions = {};
  const { dispatch } = store;
  Object.entries(actions).map(action => {
    return (boundActions[action[0]] = (...args) => {
      return dispatch(action[1](...args));
    });
  });
  return boundActions;
};

export const bindSelectors = (selectors, store) => {
  const boundSelectors = {};
  Object.entries(selectors).map(selector => {
    return (boundSelectors[selector[0]] = () => {
      return selector[1](store.getState());
    });
  });
  return boundSelectors;
};

export const camelizeKeys = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key])
      }),
      {}
    );
  }
  return obj;
};

export const addComma = number =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeComma = number => number.toString().replace(/,/g, "");
