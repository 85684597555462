import crypto from 'crypto';

import {
  isPayeezyProductionAccount,
  isPayeezyTestRequest
} from '../../api/host';

import { cartTotalSelector } from '../Cart/cart.selectors';
import { airportCodeSelector } from '../Admin/admin.selectors';

export const redirectUrlSelector = state => state.payeezy.redirectUrl;
export const isTransactionOpenSelector = state =>
  state.payeezy.isTransactionOpen;
export const firstNameSelector = state => state.payeezy.firstName;
export const lastNameSelector = state => state.payeezy.lastName;
export const emailSelector = state => state.payeezy.email;
export const payButtonLoadingSelector = state => state.payeezy.loading;
export const paymentErrorMessageSelector = state => state.payeezy.errorMessage;
export const invoiceNumSelector = state => state.payeezy.xInvoiceNum;

function makeHashCredentials(amount, airportCode) {
  let x_login;
  let transaction_key;
  const production = isPayeezyProductionAccount;
  const testRequest = isPayeezyTestRequest;
  const isProductionAccount = production => production === true;
  const isDemoAccount = production => production !== true;
  const isTestRequest = testRequest => testRequest === true;
  if (isProductionAccount(production)) {
    switch (airportCode) {
      case 'lga-c':
      case 'lga-d': {
        x_login = process.env.REACT_APP_PAYEEZY_PRODUCTION_LOGIN_LGA;
        transaction_key =
          process.env.REACT_APP_PAYEEZY_PRODUCTION_TRANSACTION_KEY_LGA;
        break;
      }
      case 'jfk-t2':
      case 'jfk-t5': {
        x_login = process.env.REACT_APP_PAYEEZY_PRODUCTION_LOGIN_JFK;
        transaction_key =
          process.env.REACT_APP_PAYEEZY_PRODUCTION_TRANSACTION_KEY_JFK;
        break;
      }
      case 'ewr-c1':
      case 'ewr-c2':
      case 'ewr-c3': {
        x_login = process.env.REACT_APP_PAYEEZY_PRODUCTION_LOGIN_EWR;
        transaction_key =
          process.env.REACT_APP_PAYEEZY_PRODUCTION_TRANSACTION_KEY_EWR;
        break;
      }

      default:
        return null;
    }
  } else {
    x_login = process.env.REACT_APP_PAYEEZY_DEMO_LOGIN;
    transaction_key = process.env.REACT_APP_PAYEEZY_DEMO_TRANSACTION_KEY;
  }
  const x_fp_sequence = process.env.REACT_APP_PAYEEZY_SEQUENCE;
  const x_fp_timestamp = Math.floor(Date.now() / 1000);
  const x_currency_code = process.env.REACT_APP_PAYEEZY_CURRENCY_CODE;
  const x_show_form = 'PAYMENT_FORM';
  const x_test_request = isTestRequest(testRequest);
  const hashCalculatorString = `${x_login}^${x_fp_sequence}^${x_fp_timestamp}^${amount}^${x_currency_code}`;
  const hmacMD5 = crypto
    .createHmac('md5', transaction_key)
    .update(hashCalculatorString)
    .digest('hex');
  const data = {
    x_login: x_login,
    x_show_form: x_show_form,
    x_fp_sequence: x_fp_sequence,
    x_fp_hash: hmacMD5,
    x_amount: amount,
    x_currency_code: x_currency_code,
    x_fp_timestamp: x_fp_timestamp
  };
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'text/html',
    credentials: 'same-origin',
    origin: 'https://fierce-mesa-75329.herokuapp.com'
  };
  return {
    data,
    isDemoAccount,
    production,
    x_test_request,
    headers,
    isProductionAccount
  };
}

export const hashSelector = state => {
  const amount = cartTotalSelector(state);
  const airportCode = airportCodeSelector(state);
  const credentials = makeHashCredentials(amount, airportCode);
  return credentials;
};
