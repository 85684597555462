import React, { memo } from "react";
import {
  selectedChecksSelector,
  selectedIdSelector,
  selectedItemsGroupCount,
  selectedModifiersSelector,
  selectedRadiosSelector
} from "../../redux/selectors";
import { updateOrAddItem, updateOrAddText } from "./UpdateOrAddButton.logic";

import { Text } from "react-native";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import styles from "./UpdateOrAddButton.style";

const UpdateOrAddButton = memo(function UpdateOrAddButton({
  addItemToCart,
  cartItemIndex,
  itemType,
  selectedChecks,
  selectedGroupCounts,
  selectedItem,
  selectedItemId,
  selectedModifiers,
  selectedRadios,
  translations,
  updateCartItem,
  price
}) {
  selectedItem = {
    ...selectedItem,
    selectedChecks,
    selectedGroupCounts,
    selectedModifiers,
    selectedRadios,
    selectedItemId
  };
  const onPress = () =>
    updateOrAddItem(
      addItemToCart,
      itemType,
      selectedItem,
      selectedItemId,
      updateCartItem,
      cartItemIndex
    );
  const buttonText = updateOrAddText(itemType, translations);
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text style={styles.buttonText}>
        {buttonText} {price}
      </Text>
    </TouchableOpacity>
  );
});

const mapStateToProps = state => ({
  selectedChecks: selectedChecksSelector(state),
  selectedItemId: selectedIdSelector(state),
  selectedRadios: selectedRadiosSelector(state),
  selectedModifiers: selectedModifiersSelector(state),
  selectedGroupCounts: selectedItemsGroupCount(state)
});

export default connect(mapStateToProps)(UpdateOrAddButton);
