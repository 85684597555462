export const EMAIL_CHANGE = 'EMAIL_CHANGE';
export const FIRST_NAME_CHANGE = 'FIRST_NAME_CHANGE';
export const FLIGHT_NUMBER_CHANGE = 'FLIGHT_NUMBER_CHANGE';
export const FRONTEND_VALIDATION_SIGNUP = 'FRONTEND_VALIDATION_SIGNUP';
export const LAST_NAME_CHANGE = 'LAST_NAME_CHANGE';
export const SEAT_NUMBER_CHANGE = 'SEAT_NUMBER_CHANGE';
export const TOGGLE_LOGGED_IN = 'TOGGLE_LOGGED_IN';
export const TOGGLE_MODAL_OPEN = 'TOGGLE_MODAL_OPEN';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const TOGGLE_PAYMENT_MODAL = 'TOGGLE_PAYMENT_MODAL';
export const HANDLE_CARD_NUMBER_CHANGE = 'HANDLE_CARD_NUMBER_CHANGE';
export const HANDLE_CVC_CHANGE = 'HANDLE_CVC_CHANGE';
export const HANDLE_EXPIRY_CHANGE = 'HANDLE_EXPIRY_CHANGE';
export const HANDLE_FLIGHT_DATE_CHANGE = 'HANDLE_FLIGHT_DATE_CHANGE';
export const HANDLE_FLIGHT_TIME_CHANGE = 'HANDLE_FLIGHT_TIME_CHANGE';
export const HANDLE_FLIGHT_DATE_TIME_CHANGE = 'HANDLE_FLIGHT_DATE_TIME_CHANGE';
export const HANDLE_CARD_TYPE_CHANGE = 'HANDLE_CARD_TYPE_CHANGE';
export const TOGGLE_FLIGHT_DETAILS_MODAL = 'TOGGLE_FLIGHT_DETAILS_MODAL';
export const TOGGLE_VALID_PAYMENT_INFO = 'TOGGLE_VALID_PAYMENT_INFO';
export const TOGGLE_VALID_FLIGHT_DETAILS = 'TOGGLE_VALID_FLIGHT_DETAILS';
export const TOGGLE_ERROR_MODAL_OPEN = 'TOGGLE_ERROR_MODAL_OPEN';
