import { Dimensions } from 'react-native';

export const types = {
  ADD_DEFAULT_CHECKBOX_AND_RADIO_MODIFIERS:
    'ADD_DEFAULT_CHECKBOX_AND_RADIO_MODIFIERS',
  ADD_SAVED_PROPS_TO_STORE: 'ADD_SAVED_PROPS_TO_STORE',
  ADD_SELECTED_CHECKBOX_MODIFIER: 'ADD_SELECTED_CHECKBOX_MODIFIER',
  ADD_SELECTED_ITEM_MODIFIER: 'ADD_SELECTED_ITEM_MODIFIER',
  CLEAR_SELECTED_ITEM: 'CLEAR_SELECTED_ITEM',
  HANDLE_SELECT_ITEM: 'HANDLE_SELECT_ITEM',
  INCREMENT_SELECTED_QUANTITY: 'INCREMENT_SELECTED_QUANTITY',
  REMOVE_SELECTED_CHECKBOX_MODIFIER: 'REMOVE_SELECTED_CHECKBOX_MODIFIER',
  REMOVE_SELECTED_ITEM_MODIFIER: 'REMOVE_SELECTED_ITEM_MODIFIER',
  SET_DEFAULT_MAX_MIN_COUNTS: 'SET_DEFAULT_MAX_MIN_COUNTS',
  TOGGLE_ITEM_MODAL: 'TOGGLE_ITEM_MODAL',
  UPDATE_APP_WIDTH: 'UPDATE_APP_WIDTH',
  UPDATE_MAX_MIN_MODIFIERS_COUNT: 'ADD_SELECTED_CHECKBOX_MODIFIER',
  UPDATE_SELECTED_RADIO_MODIFIER: 'UPDATE_SELECTED_RADIO_MODIFIER',
  UPDATE_SELECTED_UNIQUE_ID: 'UPDATE_SELECTED_UNIQUE_ID'
};

const initialState = {
  appWidth: window ? window.innerWidth : Dimensions.get('window').width,
  appHeight: window ? window.outerHeight : Dimensions.get('window').height,
  cartItemIndex: -1,
  itemModalOpen: false,
  selectedChecks: [],
  selectedGroupCounts: {},
  selectedItem: { quantity: 1 },
  selectedItemId: null,
  selectedModifiers: [],
  selectedRadios: {}
};

const ItemModalReducer = (state = initialState, action = {}) => {
  let selectedItem;
  switch (action.type) {
    case types.UPDATE_APP_WIDTH:
      return {
        ...state,
        appWidth: action.appWidth,
        appHeight: action.appHeight
      };
    case types.TOGGLE_ITEM_MODAL:
      const itemModalOpen = action.itemModalOpen;
      return {
        ...state,
        itemModalOpen
      };
    case types.HANDLE_SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
        selectedItemId: action.selectedItem.menuItemId
      };
    case types.ADD_SAVED_PROPS_TO_STORE:
      return {
        ...state,
        cartItemIndex: action.cartItemIndex,
        selectedChecks: action.selectedChecks,
        selectedGroupCounts: action.selectedGroupCounts,
        selectedItemId: action.selectedItemId,
        selectedModifiers: action.selectedModifiers,
        selectedRadios: action.selectedRadios
      };
    case types.SET_DEFAULT_MAX_MIN_COUNTS:
      return {
        ...state,
        selectedGroupCounts: action.counts
      };
    case types.ADD_SELECTED_ITEM_MODIFIER:
      return {
        ...state,
        selectedModifiers: [...state.selectedModifiers, action.optionId]
      };
    case types.REMOVE_SELECTED_ITEM_MODIFIER:
      let selectedModifiers = [...state.selectedModifiers].filter(
        id => id !== action.optionId
      );
      return {
        ...state,
        selectedModifiers
      };
    case types.ADD_DEFAULT_CHECKBOX_AND_RADIO_MODIFIERS:
      return {
        ...state,
        selectedChecks: action.checkIds,
        selectedRadios: action.radioIds,
        selectedModifiers: action.selectedModifiers
      };
    case types.ADD_SELECTED_CHECKBOX_MODIFIER:
      if (action.optionId !== undefined) {
        return {
          ...state,
          selectedChecks: [...state.selectedChecks, action.optionId]
        };
      } else {
        return { ...state };
      }
    case types.REMOVE_SELECTED_CHECKBOX_MODIFIER:
      let selectedChecks = [...state.selectedChecks].filter(
        checkId => checkId !== action.optionId
      );
      return {
        ...state,
        selectedChecks: selectedChecks
      };
    case types.UPDATE_SELECTED_RADIO_MODIFIER:
      return {
        ...state,
        selectedRadios: {
          ...state.selectedRadios,
          [action.groupId]: action.optionId
        }
      };
    case types.UPDATE_MAX_MIN_MODIFIERS_COUNT:
      if (action.sign === 'add') {
        if (state.selectedGroupCounts[action.groupId]) {
          return {
            ...state,
            selectedGroupCounts: {
              ...state.selectedGroupCounts,
              [action.groupId]: state.selectedGroupCounts[action.groupId] + 1
            }
          };
        } else {
          return {
            ...state,
            selectedGroupCounts: {
              ...state.selectedGroupCounts,
              [action.groupId]: 1
            }
          };
        }
      } else {
        if (state.selectedGroupCounts[action.groupId]) {
          return {
            ...state,
            selectedGroupCounts: {
              ...state.selectedGroupCounts,
              [action.groupId]: state.selectedGroupCounts[action.groupId] - 1
            }
          };
        } else {
          return {
            ...state,
            selectedGroupCounts: {
              ...state.selectedGroupCounts,
              [action.groupId]: 0
            }
          };
        }
      }
    case types.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: { quantity: 1 },
        selectedGroupCounts: {},
        selectedItemId: null,
        selectedModifiers: [],
        selectedRadios: {},
        selectedChecks: []
      };
    case types.UPDATE_SELECTED_UNIQUE_ID:
      return {
        ...state,
        selectedItemId: action.uniqueId
      };
    case types.INCREMENT_SELECTED_QUANTITY:
      selectedItem = { ...state.selectedItem };
      if (action.sign === 'add') {
        selectedItem.quantity += 1;
      } else {
        selectedItem.quantity -= 1;
      }
      return { ...state, selectedItem };
    default:
      return state;
  }
};

export default ItemModalReducer;
