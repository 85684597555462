import * as types from "./cart.types";

const initialState = {
  cartItems: [],
  itemModalOpen: false,
  loading: false,
  subTotal: null,
  tax: null,
  tipRate: 20,
  customTipAmount: "",
  total: null
};

const CartReducer = (state = initialState, action) => {
  let cartItems;
  switch (action.type) {
    case types.ADD_CART_ITEM:
      return { ...state, cartItems: [...state.cartItems, action.item] };
    case types.UPDATE_CART_ITEM:
      cartItems = [...state.cartItems];
      cartItems[action.cartItemIndex] = action.selectedItem;
      return { ...state, cartItems };
    case types.REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.slice(0, action.index),
          ...state.cartItems.slice(action.index + 1)
        ]
      };
    case types.INCREMENT_CART_QUANTITY:
      const isAdd = action => action.sign === "add";
      const hasCartItem = (cartItems, action) => cartItems[action.index];
      cartItems = [...state.cartItems];
      if (hasCartItem(cartItems, action)) {
        if (isAdd(action)) {
          if (action.quantity && action.quantity > 1) {
            cartItems[action.index].quantity += action.quantity;
          } else {
            cartItems[action.index].quantity += 1;
          }
        } else {
          cartItems[action.index].quantity -= 1;
        }
      }
      return { ...state, cartItems };
    case types.TOGGLE_ITEM_MODAL:
      return {
        ...state,
        itemModalOpen: action.itemModalOpen
      };
    case types.SET_CART_QUANTITY:
      cartItems = [...state.cartItems];
      cartItems[action.index].quantity = action.quantity;
      return { ...state, cartItems };
    case types.REMOVE_MODIFIER_FROM_CART_ITEM:
      var modifiers = {};
      cartItems = [...state.cartItems];
      cartItems = cartItems.map(item => {
        if (item.id === action.itemId) {
          const groupIds = Object.keys(item.modifiers);
          groupIds.map(groupId => {
            const options = item.modifiers[groupId].map(option => {
              if (option.id === action.optionId) {
                return { ...option, selected: !option.selected };
              } else {
                return { ...option, selected: option.selected };
              }
            });
            return (modifiers[groupId] = options);
          });
        } else {
          return item;
        }
        return { ...item, modifiers };
      });
      return { ...state, cartItems };
    case types.CLEAR_CART_ITEMS:
    case types.CLEAR_RESTAURANT:
      return { ...state, cartItems: [], subTotal: 0, tax: 0, total: 0 };
    case types.SET_CART_TOTAL:
      const { subTotal, tax, total } = action;
      return { ...state, subTotal, tax, total };
    case types.TOGGLE_CART_TOTAL_LOADING:
      const { loading } = action;
      return { ...state, loading };
    case types.SET_TIP_RATE:
      const { tipRate } = action;
      return { ...state, tipRate };
    case types.SET_CUSTOM_TIP_AMOUNT:
      const { customTipAmount } = action;
      return { ...state, customTipAmount };
    default:
      return { ...state };
  }
};

export default CartReducer;
