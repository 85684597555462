let secureUri;

const isSecureUri = (secureUri) => secureUri === true;
const stage = process.env.REACT_APP_STAGE;

const uriSchemeSelector = (secureUri) =>
  isSecureUri(secureUri) ? "https://" : "http://";

const hostSwitch = (stage) => {
  switch (stage) {
    case "production":
      secureUri = true;
      return "api.otgorder.com";
    case "staging":
      secureUri = true;
      return "pre-prod-api.otgorder.com";
    case "pre-prod":
      secureUri = true;
      return "pre-prod-api.otgorder.com";
    case "dev":
      secureUri = true;
      return "dev-api.otgorder.com";
    case "development":
      secureUri = false;
      return "otgorder.local/api";
  }
};

const isPayeezyProductionSwitch = (stage) => {
  switch (stage) {
    case "production":
      return true;
    case "staging":
      return false;
    case "development":
      return false;
  }
};

const isPayeezyTestSwitch = (stage) => {
  switch (stage) {
    case "production":
      return false;
    case "staging":
      return true;
    case "development":
      return true;
  }
};

const payeezyUrlSwitch = (stage) => {
  switch (stage) {
    case "production":
      return "https://checkout.globalgatewaye4.firstdata.com/payment";
    case "staging":
      return "https://demo.globalgatewaye4.firstdata.com/payment";
    case "development":
      return "https://demo.globalgatewaye4.firstdata.com/payment";
  }
};

export const imageHost = "http://oa-data-sync-prod.flosolutions.net:8081";

const host = hostSwitch(stage);
export const isPayeezyProductionAccount = isPayeezyProductionSwitch(stage);
export const isPayeezyTestRequest = isPayeezyTestSwitch(stage);
export const payeezyUrl = payeezyUrlSwitch(stage);
export const uriScheme = uriSchemeSelector(secureUri);

export default host;
