import React from "react";

import debounce from "lodash/debounce";

const PreventDoublePressHOC = WrappedComponent => {
  class PreventDoublePress extends React.PureComponent {
    debouncedOnPress = () => {
      this.props.onPress && this.props.onPress();
    };

    onPress = debounce(this.debouncedOnPress, 300, {
      leading: true,
      trailing: false
    });

    render() {
      return <WrappedComponent {...this.props} onPress={this.onPress} />;
    }
  }

  PreventDoublePress.displayName = `PreventDoublePressHOC(${WrappedComponent.displayName ||
    WrappedComponent.name})`;
  return PreventDoublePress;
};

export default PreventDoublePressHOC;
