import {
  modifierGroupsByIdSelector,
  modifierOptionsByIdSelector
} from "../Api/api.selectors";

import _ from "lodash";
import { createSelector } from "reselect";
import { pureRouterPathSelector } from "../Router/router.selectors";
import { selectedCartItemSelector } from "../Selected/selected.selectors";

export const cartItemsSelector = state => state.cart.cartItems;

export const cartItemSelector = (state, props) =>
  cartItemsSelector(state).find(cartItem => cartItem.id === props.id);

export const cartItemByIndexSelector = (state, props) => {
  const cartItems = cartItemsSelector(state);
  return cartItems[props.index];
};

export const cartItemQuantityByIndexSelector = (state, props) => {
  const cartItem = cartItemByIndexSelector(state, props);
  return cartItem.quantity;
};

export const cartItemIdsSelector = createSelector(
  cartItemsSelector,
  items =>
    items.map(item => {
      return item.id ? item.id : item.menuItemId;
    })
);

export const cartItemQuantitySelector = (state, props) => {
  const cartItem = cartItemSelector(state, props);
  return cartItem ? cartItem.quantity : 0;
};

export const newCartQuantitySelector = state => {
  const itemQuantities = [];
  const items = cartItemsSelector(state);
  if (items && items.length > 0) {
    items.map(item => {
      if (item && item.quantity) {
        return itemQuantities.push(item.quantity);
      } else {
        return 0;
      }
    });
    if (itemQuantities.length > 0) {
      return itemQuantities.reduce((acc, cur) => acc + cur);
    }
  }
  return 0;
};

const {
  hasItems,
  hasModifierGroups,
  hasModifierIds,
  hasModifiers,
  hasTitleAndPositivePrice,
  isSelected
} = {
  hasItems: items => items && items.length > 0,
  hasModifierGroups: item =>
    item && item.modifierGroups && item.modifierGroups.length > 0,
  hasModifierIds: modifierIds => modifierIds && modifierIds.length > 0,
  hasModifiers: item => item && item.modifierCollectionId !== 0,
  hasTitleAndPositivePrice: (title, price) => title && price >= 0,
  isSelected: option => option.selected === true
};

export const cartItemsUniqueIdsSelector = state => {
  const cartItemsUniqueIds = createSelector(
    cartItemsSelector,
    items => (hasItems(items) ? generateUniqueIds(items) : null)
  );

  function generateUniqueIds(items) {
    const uniqueIds = items.map(item => createUniqueId(item));
    return uniqueIds;
  }

  function createUniqueId(item) {
    const idObject = {};
    if (hasModifiers(item.modifierCollectionId)) {
      const modifierIds = collectedModifierIds(item);
      if (hasModifierIds(modifierIds)) {
        idObject[item.menuItemId] =
          item.menuItemId + "-" + modifierIds.join("-");
      } else {
        idObject[item.menuItemId] = item.menuItemId;
      }
    }
  }

  function collectedModifierIds(item) {
    const modifierIds = [];
    if (hasModifierGroups(item.modifierGroups)) {
      item.modifierGroups.map(group => {
        group.modifierItems.map(option => {
          if (isSelected(option)) {
            modifierIds.push(option.id);
          }
        });
      });
    }
    return modifierIds;
  }
  return cartItemsUniqueIds(state);
};

const createCartItemModifiers = (state, props) => {
  const modifiersArray = [];
  const selectedCartItem = selectedCartItemSelector(state);
  selectedCartItem.modifiers[props.groupId].filter(option => {
    const { selected, price, title, id, selectionType } = option;
    if (hasTitleAndPositivePrice(title, price)) {
      const modifier = {
        id,
        selected,
        title,
        price,
        selectionType
      };
      modifiersArray.push(modifier);
    }
  });
  return { [props.groupId]: modifiersArray };
};

const createMenuItemModifiers = (state, props) => {
  const modifierOptions = modifierOptionsByIdSelector(state);
  const groups = modifierGroupsByIdSelector(state);
  const items = groups[props.groupId].items;
  if (hasModifiers(items)) {
    const modifiersArray = [];
    items.map((optionId, index) => {
      let optionsState = {};
      const option = modifierOptions[optionId];
      const { selectedByDefault, price, title, id, selectionType } = option;
      optionsState[index] = selectedByDefault;
      if (hasTitleAndPositivePrice(title, price)) {
        const modifier = {
          id,
          selected: selectedByDefault,
          title,
          price,
          selectionType
        };
        modifiersArray.push(modifier);
      }
    });
    return { [props.groupId]: modifiersArray };
  }
};

export const createSelectedModifiers = (state, props) => {
  const routerPath = pureRouterPathSelector(state);
  const isCart = routerPath => routerPath === "/cart";
  if (isCart(routerPath)) {
    return createCartItemModifiers(state, props);
  } else {
    return createMenuItemModifiers(state, props);
  }
};

export const makeAllSelectedModifiersState = createSelector(
  [createSelectedModifiers],
  selectedModifiersObject => selectedModifiersObject
);

export const taxPercentSelector = state => state.cart.taxPercent;
export const tipRateSelector = state => state.cart.tipRate;
export const customTipAmountSelector = state => state.cart.customTipAmount;

export const cartItemPricesSelector = createSelector(
  cartItemsSelector,
  items => items.map(item => item.quantity * item.price)
);

export const cartItemModifierPricesSelector = state => {
  const cartItemModifierPrices = createSelector(
    cartItemsSelector,
    items => {
      const allModifiers = [];
      const hasModifiers = item => item && item.modifierCollectionId !== 0;
      const hasGroups = item => item && item.modifiers > 0;
      items.map(item => {
        if (hasModifiers(item)) {
          if (hasGroups(item)) {
            const groupIds = Object.keys(item.modifiers);
            collectGroupTotals(groupIds, item, allModifiers);
          }
        }
      });
      return _.compact(allModifiers);
    }
  );
  function collectGroupTotals(groupIds, item, allModifiers) {
    const isSelected = modifier => modifier && modifier.selected === true;
    groupIds.map(groupId => {
      item.modifiers[groupId].map(modifier => {
        if (isSelected(modifier)) {
          allModifiers.push(item.quantity * modifier.price);
        }
      });
    });
  }
  return cartItemModifierPrices(state);
};

export const subtotalSelector = createSelector(
  cartItemPricesSelector,
  cartItemModifierPricesSelector,
  (itemPrices, modifierPrices) => {
    const combinedPrices = [...itemPrices, ...modifierPrices];
    if (combinedPrices.length > 0) {
      const subtotal = combinedPrices.reduce((acc, item) => acc + item, 0);
      return subtotal;
    } else {
      return 0;
    }
  }
);

export const fixedSubtotalSelector = state => state.cart.subTotal;

export const taxSelector = createSelector(
  subtotalSelector,
  taxPercentSelector,
  (subtotal, taxPercent) => subtotal * (taxPercent / 100)
);

export const tipAmountSelector = createSelector(
  subtotalSelector,
  tipRateSelector,
  (subtotal, tipRate) => subtotal * (tipRate / 100)
);

export const fixedTaxSelector = state => state.cart.tax;

export const totalSelector = createSelector(
  subtotalSelector,
  fixedTaxSelector,
  tipAmountSelector,
  (subtotal, tax, tipAmount) => {
    console.log("totalSelector tax: " + tax);
    console.log("totalSelector: ", subtotal, tax, tipAmount);
    return (subtotal + tax + tipAmount).toFixed(2);
  }
);

export const fixedTotalSelector = createSelector(
  totalSelector,
  total => total.toFixed(2)
);

// price array calculations with higher order selectors

export const addComma = number =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeComma = number => number.toString().replace(/,/g, "");

const preparePriceValues = (
  menuItemPrice,
  modifiersTotal,
  quantity,
  displayPriceInMiles
) => {
  // currency
  const itemCurrency = Number(menuItemPrice);
  const itemCurrencyWithQuantity = (itemCurrency + modifiersTotal) * quantity;
  const itemCurrencyWithoutQuantity = itemCurrency + modifiersTotal;
  const currency = itemCurrencyWithQuantity.toFixed(2);
  const singleItemCurrency = itemCurrencyWithoutQuantity.toFixed(2);
  // miles
  const itemMiles = Number(removeComma(displayPriceInMiles));
  const miles = (itemMiles + modifiersTotal) * quantity;
  const singleItemMiles = itemMiles + modifiersTotal;
  return { currency, singleItemCurrency, singleItemMiles, miles };
};

const createItemWithModifiersTotal = (
  menuItemPrice,
  modifiersTotal,
  quantity,
  displayPriceInMiles,
  paymentType
) => {
  let {
    singleItemCurrency,
    currency,
    singleItemMiles,
    miles
  } = preparePriceValues(
    menuItemPrice,
    modifiersTotal,
    quantity,
    displayPriceInMiles
  );
  switch (paymentType) {
    case "CREDITCARD":
      return { currency, singleItemCurrency };
    case "MILES":
      miles = addComma(miles);
      return { singleItemMiles, miles };
    case "JOINTAB":
      return { singleItemCurrency, currency };
    default:
      return { singleItemCurrency, currency };
  }
};

const getModifierPricesArray = (optionIds, options, paymentType) => {
  return optionIds.map(id => {
    const currency = Number(options[id].price);
    const airlineMiles = options[id].airlineMiles;
    const displayPriceInMiles = options[id].displayPriceInMiles;
    const miles = airlineMiles ? airlineMiles : displayPriceInMiles;
    switch (paymentType) {
      case "CREDITCARD":
        return currency;
      case "MILES":
        return miles;
      case "JOINTAB":
        return currency;
      default:
        return currency;
    }
  });
};

export const selectedModifiersTotal = (state, props) => {
  const optionIds = props.selectedItem.selectedModifiers;
  const options = modifierOptionsByIdSelector(state, props);
  const addPrices = prices => prices.reduce((acc, cur) => acc + cur, 0);
  const areThereModifierPrices = modifierPrices => modifierPrices !== undefined;
  const hasSelectedOptions = optionIds => optionIds && optionIds.length > 0;
  if (hasSelectedOptions(optionIds)) {
    const paymentType = "CREDITCARD";
    const modifierPrices = getModifierPricesArray(
      optionIds,
      options,
      paymentType
    );
    if (areThereModifierPrices(modifierPrices)) {
      const modifierTotal = addPrices(modifierPrices);
      return modifierTotal;
    }
  }
  return 0;
};

export const selectedItemWithModifiersTotal = (state, props) => {
  const itemExists = item => item !== undefined;
  const quantityExists = quantity => quantity !== undefined;
  const modifiersTotal = selectedModifiersTotal(state, props);
  const item = props.selectedItem;
  if (itemExists(item) && item.menuItemPrice) {
    const { quantity, menuItemPrice, displayPriceInMiles } = item;
    const paymentType = "CREDITCARD";
    if (quantityExists(quantity)) {
      const totalWithModifiers = createItemWithModifiersTotal(
        menuItemPrice,
        modifiersTotal,
        quantity,
        displayPriceInMiles,
        paymentType
      );
      return totalWithModifiers;
    }
  } else {
    return 0;
  }
};

export const makeSelectedItemWithModifiersTotal = () =>
  createSelector(
    [selectedItemWithModifiersTotal],
    itemPricesObject => itemPricesObject
  );

// Papi Cart Totals

export const cartTotalSelector = state => state.cart.total;
export const cartTaxSelector = state => state.cart.tax;

export const cartTotalLoadingSelector = state => state.cart.loading;

export const cartItemsByUniqueId = createSelector(
  cartItemsSelector,
  cartItems => {
    const cartItemsObject = {};
    cartItems.map(item => (cartItemsObject[item.uniqueId] = { ...item }));
    return cartItemsObject;
  }
);

export const itemAddedSelector = (state, props) => {
  const cartIds = cartItemIdsSelector(state);
  const added = cartIds.indexOf(props.id) !== -1;
  return added;
};

export const itemPriceWithDefaultModifiersSelector = (state, props) => {
  const itemPriceWithDefaultModifiers =
    Number(props.price) + Number(props.defaultModifierTotalPrice);
  return itemPriceWithDefaultModifiers.toFixed(2);
};
