import * as types from "./selected.types";

const initialState = {
  selectedCartIndex: null,
  selectedCategory: ""
};

const SelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_CART_INDEX:
      return {
        ...state,
        selectedCartIndex: action.selectedCartIndex
      };
    case types.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory
      };
    default:
      return state;
  }
};

export default SelectedReducer;
