import { createSelector } from "reselect";
import { addComma, removeComma, bindSelectors } from "./helpers";
import store from "./store";

export const appHeightSelector = state => state.itemModal.appHeight;
export const appWidthSelector = state => state.itemModal.appWidth;
export const cartItemIndexSelector = state => state.itemModal.cartItemIndex;
export const itemModalOpenSelector = state => state.itemModal.itemModalOpen;

export const selectedChecksSelector = state => state.itemModal.selectedChecks;
export const selectedItemSelector = state => {
  return state.itemModal.selectedItem;
};
export const selectedIdSelector = state => state.itemModal.selectedItemId;
export const selectedRadiosSelector = state => state.itemModal.selectedRadios;

export const selectedItemQuantitySelector = state => {
  const selectedItem = selectedItemSelector(state);
  return selectedItem.quantity;
};

const getModifierPricesArray = (optionIds, options, paymentType) => {
  return optionIds.map(id => {
    const currency = Number(options[id].menuItemPrice);
    const airlineMiles = options[id].airlineMiles;
    const displayPriceInMiles = options[id].displayPriceInMiles;
    const miles = airlineMiles ? airlineMiles : displayPriceInMiles;
    switch (paymentType) {
      case "CREDITCARD":
        return currency;
      case "MILES":
        return miles;
      case "JOINTAB":
        return currency;
      default:
        return currency;
    }
  });
};

const preparePriceValues = (
  menuItemPrice,
  modifiersTotal,
  quantity,
  displayPriceInMiles
) => {
  // currency
  const itemCurrency = Number(menuItemPrice);
  const itemCurrencyWithQuantity = (itemCurrency + modifiersTotal) * quantity;
  const currency = itemCurrencyWithQuantity.toFixed(2);
  // miles
  const itemMiles = Number(removeComma(displayPriceInMiles));
  const miles = (itemMiles + modifiersTotal) * quantity;
  return { currency, miles };
};

const createItemWithModifiersTotal = (
  menuItemPrice,
  modifiersTotal,
  quantity,
  displayPriceInMiles,
  paymentType
) => {
  const { currency, miles } = preparePriceValues(
    menuItemPrice,
    modifiersTotal,
    quantity,
    displayPriceInMiles
  );
  switch (paymentType) {
    case "CREDITCARD":
      return currency;
    case "MILES":
      return addComma(miles);
    case "JOINTAB":
      return currency;
    default:
      return currency;
  }
};

export const selectedModifiersSelector = state =>
  state.itemModal.selectedModifiers;

export const selectedItemsGroupCount = state =>
  state.itemModal.selectedGroupCounts;

export const selectedModifiersTotal = (state, props) => {
  const optionIds = selectedModifiersSelector(state);
  const options = modifierOptionsByIdSelector(state, props);
  const addPrices = prices => prices.reduce((acc, cur) => acc + cur, 0);
  const areThereModifierPrices = modifierPrices => modifierPrices !== undefined;
  const hasSelectedOptions = optionIds => optionIds && optionIds.length > 0;
  if (hasSelectedOptions(optionIds)) {
    const paymentType = props.paymentType;
    const modifierPrices = getModifierPricesArray(
      optionIds,
      options,
      paymentType
    );
    if (areThereModifierPrices(modifierPrices)) {
      const modifierTotal = addPrices(modifierPrices);
      return modifierTotal;
    }
  }
  return 0;
};

export const selectedItemWithModifiersTotal = (state, props) => {
  const { itemExists, quantityExists, modifiersTotal, item } = {
    item: selectedItemSelector(state),
    itemExists: item => item !== undefined,
    modifiersTotal: selectedModifiersTotal(state, props),
    quantityExists: quantity => quantity !== undefined
  };
  if (itemExists(item) && item.menuItemPrice) {
    const { quantity, menuItemPrice, displayPriceInMiles } = item;
    const { paymentType } = props;
    if (quantityExists(quantity)) {
      return createItemWithModifiersTotal(
        menuItemPrice,
        modifiersTotal,
        quantity,
        displayPriceInMiles,
        paymentType
      );
    }
  } else {
    return 0;
  }
};

export const modifierOptionsSelector = (state, props) => props.modifierOptions;

export const modifierOptionSelector = (state, props) => {
  return props.modifierOptions.filter(
    option => option.menuItemId === props.optionId
  );
};

export const modifierOptionsByIdSelector = createSelector(
  modifierOptionsSelector,
  modifierOptions => {
    const modifierOptionsById = {};
    modifierOptions.map(option => {
      return (modifierOptionsById[option.menuItemId] = option);
    });
    return modifierOptionsById;
  }
);

export const isCheckBoxSelected = (state, props) => {
  const selectedChecks = selectedChecksSelector(state);
  const isSelected = selectedChecks.includes(props.optionId);
  return isSelected;
};

export const isCheckBoxSelectedSelector = createSelector(
  isCheckBoxSelected,
  isSelected => isSelected
);

export const isRadioSelected = (state, props) => {
  const selectedRadios = selectedRadiosSelector(state);
  const isSelected = selectedRadios[props.groupId] === props.optionId;
  return isSelected;
};

export const isRadioSelectedSelector = createSelector(
  isRadioSelected,
  isSelected => isSelected
);

export const groupItemCountSelector = (state, props) => {
  const groupItemCount = selectedItemsGroupCount(state);
  return groupItemCount[props.groupId];
};

const { isPhone, isTablet, isDesktop } = {
  isDesktop: (height, width) => width > 1200 || height > 1200,
  isPhone: (width, height) => width <= 425 || height <= 425,
  isTablet: width => width >= 426 && width <= 1200
};

export const deviceSelector = state => {
  const { height, width } = {
    height: appHeightSelector(state),
    width: appWidthSelector(state)
  };
  const getDevice = (width, height) => {
    if (isPhone(width, height)) {
      return "phone";
    } else if (isTablet(width)) {
      return "tablet";
    } else if (isDesktop(width, height)) {
      return "desktop";
    }
  };
  const device = getDevice(width, height);
  return device;
};

const { getOrientation } = {
  getOrientation: (width, height) => (width < height ? "portrait" : "landscape")
};

export const orientationSelector = state => {
  const { device, height, width } = {
    device: deviceSelector(state),
    height: appHeightSelector(state),
    width: appWidthSelector(state)
  };
  let orientation = getOrientation(width, height);
  if (isTablet(device) || isDesktop(device)) {
    orientation = "landscape";
  }
  return orientation;
};

const selectors = {
  itemModalOpen: itemModalOpenSelector,
  selectedChecks: selectedChecksSelector,
  selectedId: selectedIdSelector,
  selectedItem: selectedItemSelector,
  selectedItemsGroupCount: selectedItemsGroupCount,
  selectedItemWithModifiersTotal: selectedItemWithModifiersTotal,
  selectedModifiers: selectedModifiersSelector,
  selectedModifiersTotal: selectedModifiersTotal,
  selectedRadios: selectedRadiosSelector
};

const boundSelectors = bindSelectors(selectors, store);
export default boundSelectors;
