import "./App.css";

import * as actions from "./redux/actions";

import React, { PureComponent } from "react";
import {
  airportSelector,
  getairportCodeSelector,
  terminalConcourseSelector,
} from "./redux/Admin/admin.selectors";
import {
  loadingMessageSelector,
  loadingSelector,
} from "./redux/Api/api.selectors";

import ConnectedWithRoutes from "./hocs/ConnectedWithRoutes";
import Loader from "./common/Loader/Loader";
import Router from "./router";
import memoizeOne from "memoize-one";
import { push } from "redux-first-history";
import queryString from "query-string";

// import { RootReducer as storeRouter } from "./redux/reducers";

// import { toggleLoading } from "./redux/Api/api.actions";

const displayAppVersion = memoizeOne(function displayAppVersion() {
  console.log(
    `%c ===> ${process.env.REACT_APP_NAME}: ${
      process.env.REACT_APP_VERSION
    } <===`,
    "background: #222; color: #bada55"
  );
});

const whyDidUpdate = (enabled = true) => {
  if (enabled && process.env.NODE_ENV !== "production") {
    const { whyDidYouUpdate } = require("why-did-you-update");
    whyDidYouUpdate(React);
  }
};

class App extends PureComponent {
  state = {};
  componentDidMount() {
    // A custom hook that builds on useLocation to parse
    // the query string for you.
    const query = queryString.parse(this.props.location.search);
    // console.log("query strings: ", query);
    const queryAirport = query.airport;
    const queryTerminal = query.terminal;
    // console.log("query string airport: ", queryAirport);
    // console.log("query string terminal: ", queryTerminal);
    if (queryAirport) {
      //set the airport and terminal into state from the query string.
      this.props.setAirportFromQuery(queryAirport);
    }
    if (queryTerminal) {
      this.props.setTerminalFromQuery(queryTerminal);
    }

    displayAppVersion();
    whyDidUpdate(false);
    this.props.toggleLoading(false);
  }

  render() {
    if (this.props.loading) {
      return <Loader message={this.props.loadingMessage} />;
    } else {
      return <Router />;
    }
  }
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  message: loadingMessageSelector(state),
  airport: airportSelector(state),
  terminal: terminalConcourseSelector(state),
  airportCode: getairportCodeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAirportFromQuery: (airport) => {
    dispatch(actions.onAirportChange(airport));
  },
  setTerminalFromQuery: (terminal) => {
    dispatch(actions.onTerminalConcourseChange(terminal));
  },
  push: (to) => {
    push(to);
  },
  toggleLoading: (isLoading) => {
    dispatch(actions.toggleLoading(isLoading));
  },
});

//{ push, toggleLoading }
export default ConnectedWithRoutes(mapStateToProps, mapDispatchToProps)(App);

// let stringified = queryString.stringify({
//   airport: "John F. Kennedy International Airport (JFK)",
// });
// console.log("query string safe value: ", stringified);
// http://localhost:3000/categories?airport=Newark%20Liberty%20International%20Airport%20%28EWR%29&terminal=Concourse%201%20%28Gates%2070-99%29&venueID=15
//Airport and Terminal Query Strings
//Newark Liberty International Airport (EWR)        Newark%20Liberty%20International%20Airport%20%28EWR%29
//    Concourse 1 (Gates 70-99)     Concourse%201%20%28Gates%2070-99%29
//    Concourse 2 (Gates 101-115)   Concourse%202%20%28Gates%20101-115%29
//    Concourse 3 (Gates 120-139)   Concourse%203%20%28Gates%20120-139%29

//John F. Kennedy International Airport (JFK)       John%20F.%20Kennedy%20International%20Airport%20%28JFK%29
//    Terminal 2    Terminal%202
//    Terminal 5    Terminal%205

//LaGuardia Airport (LGA)                           LaGuardia%20Airport%20%28LGA%29
//    Terminal C    Terminal%20C
//    Terminal D    Terminal%20D

//http://localhost:3000/?airport=&terminal=Concourse%201%20%28Gates%2070-99%29&venueID=15
