import * as types from './login.types';

import Immutable from 'seamless-immutable';

const initialState = Immutable({
  cardNumber: '',
  cardType: '',
  cvc: '',
  email: '',
  errorMessages: [],
  errorModalOpen: false,
  expiry: '',
  firstName: '',
  flightDate: 'Today',
  pickupDetailsOpen: false,
  flightNumber: '',
  flightTime: '',
  lastName: '',
  loggedIn: false,
  modalOpen: false,
  paymentModalOpen: false,
  seatNumber: '',
  validPickupDetails: false,
  validPaymentInfo: false,
  initialFlightTimeChange: 0
});

// const initialState = Immutable({
//   cardNumber: "4788250000028291",
//   cardType: "visa",
//   cvc: "123",
//   email: "dmieloch@otgexp.com",
//   errorMessages: [],
//   errorModalOpen: false,
//   expiry: "1230",
//   firstName: "Dave",
//   flightDate: "12-14-2018",
//   pickupDetailsOpen: true,
//   flightNumber: "A123",
//   flightTime: "09:25",
//   lastName: "Mieloch",
//   loggedIn: false,
//   modalOpen: false,
//   paymentModalOpen: false,
//   seatNumber: "123",
//   validPickupDetails: true,
//   validPaymentInfo: false
// });

const LoginReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.EMAIL_CHANGE:
      return { ...state, email: action.payload };
    case types.FIRST_NAME_CHANGE:
      return { ...state, firstName: action.payload };
    case types.FLIGHT_NUMBER_CHANGE:
      return { ...state, flightNumber: action.payload };
    case types.FRONTEND_VALIDATION_SIGNUP:
      return { ...state };
    case types.LAST_NAME_CHANGE:
      return { ...state, lastName: action.payload };
    case types.SEAT_NUMBER_CHANGE:
      return { ...state, seatNumber: action.payload };
    case types.TOGGLE_LOGGED_IN:
      return { ...state, loggedIn: action.loggedIn };
    case types.TOGGLE_MODAL_OPEN:
      return { ...state, modalOpen: action.payload };
    case types.UPDATE_ERROR_MESSAGE:
      return { ...state, errorMessages: action.errorMessages };
    case types.TOGGLE_ERROR_MODAL_OPEN:
      return { ...state, errorModalOpen: action.payload };
    case types.HANDLE_CARD_NUMBER_CHANGE:
      return { ...state, cardNumber: action.payload };
    case types.HANDLE_CVC_CHANGE:
      return { ...state, cvc: action.payload };
    case types.HANDLE_EXPIRY_CHANGE:
      return { ...state, expiry: action.payload };
    case types.HANDLE_FLIGHT_DATE_CHANGE:
      return { ...state, ...action.payload };
    case types.HANDLE_FLIGHT_TIME_CHANGE:
      return { ...state, ...action.payload };
    case types.HANDLE_FLIGHT_DATE_TIME_CHANGE:
      return { ...state, ...action.payload };
    case types.HANDLE_CARD_TYPE_CHANGE:
      return { ...state, cardType: action.payload };
    case types.TOGGLE_FLIGHT_DETAILS_MODAL:
      return { ...state, pickupDetailsOpen: action.pickupDetailsOpen };
    case types.TOGGLE_VALID_FLIGHT_DETAILS:
      return { ...state, validPickupDetails: action.validPickupDetails };
    case types.TOGGLE_PAYMENT_MODAL:
      return { ...state, paymentModalOpen: action.paymentModalOpen };
    case types.TOGGLE_VALID_PAYMENT_INFO:
      return { ...state, validPaymentInfo: action.validPaymentInfo };
    default:
      return state;
  }
};

export default LoginReducer;
