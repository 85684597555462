import PreventDoublePressHoc from "../../../../organism/helpers/PreventDoublePress";

import { TouchableOpacity } from "react-native";

export const SafeTouchableOpacity = PreventDoublePressHoc(TouchableOpacity);

// ModifierOption

const getPositiveMilesValue = (airlineMiles, displayPriceInMiles) => {
  return airlineMiles ? airlineMiles : displayPriceInMiles;
};
const isPositiveValue = value => Number(value) > 0;
export const price = ({
  paymentType,
  menuItemPrice,
  airlineMiles,
  displayPriceInMiles
}) => {
  const currency = isPositiveValue(menuItemPrice)
    ? `+ $${menuItemPrice}`
    : null;
  const positiveMilesValue = getPositiveMilesValue(
    airlineMiles,
    displayPriceInMiles
  );
  const miles = isPositiveValue(positiveMilesValue)
    ? `+ ${positiveMilesValue} miles`
    : null;
  switch (paymentType) {
    case "CREDITCARD":
      return currency;
    case "MILES":
      return miles;
    case "JOINTAB":
      return currency;
    default:
      return currency;
  }
};

export const isCheckbox = selectionType => selectionType === "CHECKBOX";

export const isCheckOrRadio = (
  selectionType,
  minimumSelections,
  maximumSelections
) =>
  isCheckbox(selectionType) &&
  checkIsNotRadioButton(minimumSelections, maximumSelections);

const checkIsNotRadioButton = (minimumSelections, maximumSelections) => {
  return minimumSelections === 0 && maximumSelections > 1;
};

export const removePrice = title => title.replace(/\$[\s\S]*$/, "");
