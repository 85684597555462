export const { hasModifiers, isCart, isItemInCart, updateOrAddText } = {
  hasModifiers: groups => groups && groups.length > 0,
  isCart: itemType => itemType === "cart",
  isItemInCart: index => index !== -1,
  updateOrAddText: (itemType, translations) =>
    isCart(itemType) ? translations.updateCheck : translations.addToCheck
};

export const updateOrAddItem = (
  addItemToCart,
  itemType,
  selectedItem,
  selectedItemId,
  updateCartItem,
  cartItemIndex
) => {
  selectedItem.uniqueId = selectedItemId;
  selectedItem.itemType = "cart";
  if (isCart(itemType)) {
    return updateCartItem(selectedItem, cartItemIndex);
  } else {
    if (hasModifiers(selectedItem.modifierGroups)) {
      return addItemToCart(
        selectedItem,
        selectedItemId,
        itemType,
        selectedItem.quantity
      );
    } else {
      if (isItemInCart(cartItemIndex)) {
        return updateCartItem(selectedItem, cartItemIndex);
      } else {
        return addItemToCart(
          selectedItem,
          selectedItemId,
          itemType,
          selectedItem.quantity
        );
      }
    }
  }
};
