import * as types from "./cart.types";

export const createSelectedUniqueId = item => {
  const helpers = {
    generateUniqueId: (modifierIds, item, uniqueId) => {
      if (helpers.hasModifierIds(modifierIds)) {
        const idWithModifiers = item.id + "-" + modifierIds.join("-");
        uniqueId = idWithModifiers;
      } else {
        uniqueId = item.id.toString();
      }
      return uniqueId;
    },
    hasModifierIds: modifierIds => modifierIds.length > 0,
    sortIds: modifierIds => modifierIds.sort((a, b) => a - b)
  };

  return dispatch => {
    let modifierIds = item.selectedModifiers;
    modifierIds = helpers.sortIds(modifierIds);
    const uniqueId = helpers.generateUniqueId(modifierIds, item, uniqueId);
    dispatch({ type: types.UPDATE_SELECTED_UNIQUE_ID, uniqueId });
    return uniqueId;
  };
};
