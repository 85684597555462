import { dashToHeading, dashToSpaces } from "../../common/common";

export const routerPathHeadingSelector = state =>
  dashToHeading(state.router.location.pathname)
    .replace(/^(.*[\\/])/, "")
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const routerPathSelector = state =>
  dashToSpaces(state.router.location.pathname.replace(/^(.*[\\/])/, ""));

export const pureRouterPathSelector = state => state.router.location.pathname;
