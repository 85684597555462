import React, { memo } from "react";

import GroupSelectionString from "../atoms/GroupSelectionString";
import GroupTitle from "../atoms/GroupTitle";
import ModifierOption from "./ModifierOption/ModifierOption";
import { generate as key } from "shortid";

const lightGrey = "#d1d1d1";

const styles = {
  container: {
    backgroundColor: "#f4f4f4"
  },
  groupName: {
    marginTop: 7,
    marginBottom: 20,
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: 13,
    paddingRight: 13
  },
  separator: {
    backgroundColor: lightGrey,
    height: 1,
    marginBottom: 10,
    width: "100%"
  }
};

const ModifierGroup = memo(function ModifierGroup({
  group,
  groupIndex,
  modifierGroups,
  modifierItems,
  modifierOptions,
  paymentType,
  selectedItem,
  title
}) {
  const { minimumSelections, maximumSelections } = group;
  const isRequired = minimumSelections >= 1;
  const selectString = isRequired ? "Required" : "Optional";
  const minSelection =
    minimumSelections > 0 ? " - Choose at least " + minimumSelections : "";
  const maxSelections =
    maximumSelections < 1000 ? " - Choose up to " + maximumSelections : "";
  const selectionString =
    modifierItems.length > 1 ? selectString + minSelection + maxSelections : "";
  console.log(selectionString);
  //      <GroupSelectionString selectString={selectionString} />
  //      <GroupTitle title={selectionString} />

  return (
    <div style={styles.container}>
      <GroupTitle title={title} />
      <GroupSelectionString selectString={selectionString} />
      {modifierItems.map((option, optionIndex) => {
        return (
          <ModifierOption
            group={group}
            groupIndex={groupIndex}
            key={key()}
            modifierGroups={modifierGroups}
            modifierOptions={modifierOptions}
            option={option}
            optionIndex={optionIndex}
            paymentType={paymentType}
            selectedItem={selectedItem}
          />
        );
      })}
    </div>
  );
});

export default ModifierGroup;
