import {
  ButtonHotSpot,
  checkboxSrcImage,
  enforceMaxAndMinSelections
} from "./Common/Common.logic";
import { Checkbox, Container } from "./Common/Common.style";
import React, { memo } from "react";
import {
  groupItemCountSelector,
  isCheckBoxSelectedSelector,
  modifierOptionSelector
} from "../../../redux/selectors";

import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { selectedChecksSelector } from "../../../redux/selectors";
import { toggleCheckBox } from "../../../redux/actions";

const CheckBox = memo(function CheckBox({
  groupId,
  groupIndex,
  groupItemCount,
  isSelected,
  maximumSelections,
  minimumSelections,
  optionId,
  optionIndex,
  selectedItem,
  toggleCheckBox
}) {
  return (
    <Container>
      <TouchableOpacity
        onPress={() =>
          enforceMaxAndMinSelections({
            optionId,
            groupId,
            groupIndex,
            groupItemCount,
            maximumSelections,
            minimumSelections,
            optionIndex,
            isSelected,
            selectedItem,
            toggleCheckBox
          })
        }
        style={ButtonHotSpot}
      >
        <Checkbox src={checkboxSrcImage(isSelected)} />
      </TouchableOpacity>
    </Container>
  );
});

const mapStateToProps = (state, props) => ({
  selectedChecks: selectedChecksSelector(state),
  modifierOption: modifierOptionSelector(state, props),
  isSelected: isCheckBoxSelectedSelector(state, props),
  groupItemCount: groupItemCountSelector(state, props)
});

export default connect(
  mapStateToProps,
  { toggleCheckBox }
)(CheckBox);
