import styled from "styled-components";

export const Container = styled.div.attrs(props => ({
  hasModifiers: props.hasModifiers
}))`
  background-color: white;
  flex: 1;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  @media (max-width: 500px) {
    height: 100%;
    padding-top: 0px;
    width: ${window.innerWidth};
  }
  @media (min-width: 500px) and (max-width: 1025px) {
    padding-top: 0px;
    // width: 768px;
  }
  @media (min-width: 1024px) {
    max-height: 768px;
    padding-top: 0px;
    width: ${props => (props.hasModifiers ? 768 : 390)}px;
  }
`;

export const ContentContainer = styled.div.attrs(props => ({
  hasModifiers: props.hasModifiers,
  style: props.style
}))`
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 500px) {
    max-width: ${props => (props.hasModifiers ? 800 : 390)}px;
    width: ${window.innerWidth}px;
  }
  @media (min-width: 501px) {
    max-width: ${props => (props.hasModifiers ? 800 : 390)}px;
    width: 100%;
  }
`;

export const Row = styled.div.attrs(props => ({
  hasModifiers: props.hasModifiers
}))`
  display: flex;
  height: max-content;
  margin-right: 13px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 500px) {
    height: max-content;
    display: block;
  }
  @media (min-width: 500px) and (max-width: 800px) {
    width: ${window.innerWidth}px;
    ${props => props.hasModifiers && { "flex-direction": "row" }};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    width: ${window.innerWidth}px;
  }
  @media (min-width: 500px) and (max-width: 725px) {
    max-width: 20rem;
  }
  @media (min-width: 726px) {
    width: 395px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-around;
  margin: 16px;
  width: ${window.innerWidth}-32;
`;
