import * as types from "../../redux/Cart/cart.types";

import {
  cartItemsByUniqueId,
  cartItemsSelector,
  cartTotalSelector
} from "../../redux/Cart/cart.selectors";
import host, { uriScheme } from "../../api/host";

import { airportCodeSelector } from "../Admin/admin.selectors";
import { convertItems } from "../Payeezy/payeezy.actions";
import { createSelectedUniqueId } from "./uniqueItem.actions";
import { toggleItemModal } from "../../common/ItemModal";

export const addToCart = item => ({
  type: types.ADD_CART_ITEM,
  item
});

export const addCartItem = item => {
  return (dispatch, getState) => {
    const state = getState();
    const cartItemsObject = cartItemsByUniqueId(state);
    let uniqueId = dispatch(createSelectedUniqueId(item));
    if (cartItemsObject[uniqueId] === undefined) {
      const { id, quantity, modifierCollectionId } = item;
      const hasModifiers = id => id === 0;
      uniqueId = hasModifiers(modifierCollectionId) ? id : uniqueId;
      return dispatch(addToCart({ ...item, quantity, uniqueId }));
    }
  };
};

export const addOrUpdateCart = (item, itemId, quantity) => {
  const uniqueIdMatch = index => index !== -1;
  return (dispatch, getState) => {
    const state = getState();
    const cartItems = cartItemsSelector(state);
    const index = cartItems.findIndex(
      item => item.uniqueId === itemId.toString()
    );
    if (uniqueIdMatch(index)) {
      dispatch(incrementCartQuantity(itemId, "add", index, quantity));
    } else {
      item.uniqueId = itemId;
      item.quantity = quantity;
      dispatch(addCartItem(item));
    }
    dispatch(toggleItemModal(false));
  };
};

export const addItemToCart = (
  selectedItem,
  selectedItemId,
  itemType,
  quantity
) => {
  return async dispatch => {
    console.log("addItemToCart");
    if (isCartOrMenuItem(itemType)) {
      await dispatch(addOrUpdateCart(selectedItem, selectedItemId, quantity));
    } else if (isTabItem({ itemType })) {
      // to be implemented when needed
    }
  };
};

export const updateCartItem = (selectedItem, cartItemIndex) => {
  return dispatch => {
    dispatch({
      type: types.UPDATE_CART_ITEM,
      selectedItem,
      cartItemIndex
    });
    dispatch(toggleItemModal(false));
  };
};

export const isCartOrMenuItem = itemType => {
  return itemType === "cart" || itemType === "menuItem";
};

export const isTabItem = itemType => {
  return itemType === "tab";
};

export const addCartOrMenuItem = (selectedItem, selectedItemId) => {
  return dispatch => {
    selectedItem.uniqueId = selectedItemId;
    selectedItem.id = selectedItemId;
    dispatch(addToCart(selectedItem));
    dispatch(toggleItemModal(false));
  };
};

export const addToCartItemsQuantityModal = (menuItem, quantity) => {
  return dispatch => {
    const uniqueId = dispatch(createSelectedUniqueId(menuItem));
    menuItem.quantity = quantity;
    menuItem.uniqueId = uniqueId;
    dispatch(addCartItem(menuItem));
  };
};

export const removeFromCartItems = (itemId, index) => ({
  type: types.REMOVE_CART_ITEM,
  itemId,
  index
});

export const removeModifierFromCartItem = (itemId, optionId) => ({
  type: types.REMOVE_MODIFIER_FROM_CART_ITEM,
  itemId,
  optionId
});

// cart item quantity actions

export const incrementCartQuantity = (itemId, sign, index, quantity) => ({
  type: types.INCREMENT_CART_QUANTITY,
  itemId,
  sign,
  index,
  quantity
});

export const setCartQuantity = (quantity, index) => ({
  type: types.SET_CART_QUANTITY,
  quantity,
  index
});

export const onDecrementMinusClick = (id, quantity, index) => {
  return dispatch => {
    if (quantity <= 1) {
      return dispatch(removeFromCartItems(id, index));
    }
    dispatch(incrementCartQuantity(id, "subtract", index));
  };
};

export const onIncrementPlusClick = (id, index) => {
  return dispatch => {
    dispatch(incrementCartQuantity(id, "add", index));
  };
};

export const clearCartItems = () => ({
  type: types.CLEAR_CART_ITEMS
});

export const clearRestaurant = () => ({
  type: types.CLEAR_RESTAURANT
});

export const setTipRate = tipRate => ({
  type: types.SET_TIP_RATE,
  tipRate
});

export const setCustomTipAmount = customTipAmount => ({
  type: types.SET_CUSTOM_TIP_AMOUNT,
  customTipAmount
});

// OTG API Cart Totaling Api, subtotal - tax - total

const getBody = ({
  airportCode,
  items,
  calcTotalOnly,
  tipAmount,
  forwardedFor
}) => {
  return {
    airportCode,
    items,
    calc_total_only: calcTotalOnly,
    tip_amount: tipAmount,
    "X-Forwarded-For": forwardedFor
  };
};

const getOptions = body => {
  return {
    body: JSON.stringify(body),
    credentials: "same-origin",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      cache: "no-cache",
      credentials: "include"
    },
    method: "POST",
    mode: "cors"
  };
};

const fetchCartTotal = async options => {
  let url = uriScheme + host + `/papi/order/cart`;
  try {
    const res = await fetch(url, options);
    const json = res.json();
    return json;
  } catch (err) {
    console.log("fetchCartTotal", err.message);
  }
};

export function getCartTotal({ cartItems }) {
  return async (dispatch, getState) => {
    const state = getState();
    const airportCode = airportCodeSelector(state);
    const items = convertItems(cartItems);
    // const tipAmount = tipAmountSelector(state).toFixed(2);
    const body = getBody({
      airportCode,
      calcTotalOnly: 1,
      forwardedFor: `${host}`,
      items
    });
    const options = getOptions(body);
    try {
      const cartTotals = await fetchCartTotal(options);
      const { data } = cartTotals;
      const subTotal = data.subTotal;
      const tax = data.tax;
      const total = data.total;
      dispatch(setCartTotal(subTotal, tax, total));
    } catch (err) {
      dispatch(setCartTotal("error", "error", "error"));
      console.log("getCartTotal", err.message);
    }
  };
}

export const setCartTotal = (subTotal, tax, total) => ({
  type: types.SET_CART_TOTAL,
  subTotal,
  tax,
  total
});

export const toggleCartTotalLoading = loading => ({
  type: types.TOGGLE_CART_TOTAL_LOADING,
  loading
});

/* Middleware */

const hasCartItems = cartItems => {
  return cartItems && cartItems.length > 0;
};

const hasPositiveTotal = total => {
  return total > 0;
};

const getTotalsFromApi = (cartItems, action) => {
  return async dispatch => {
    switch (action.type) {
      case types.ADD_CART_ITEM:
      case types.UPDATE_CART_ITEM:
      case types.REMOVE_CART_ITEM:
      case types.SET_CART_QUANTITY:
      case types.INCREMENT_CART_QUANTITY:
      case types.REMOVE_MODIFIER_FROM_CART_ITEM:
      case types.CLEAR_CART_ITEMS:
        dispatch(toggleCartTotalLoading(true));
        await dispatch(getCartTotal({ cartItems }));
        dispatch(toggleCartTotalLoading(false));
        break;
      default:
        break;
    }
  };
};

const resetTotals = action => {
  return dispatch => {
    const subTotal = 0;
    const tax = 0;
    const total = 0;
    switch (action.type) {
      case types.REMOVE_CART_ITEM:
      case types.REMOVE_MODIFIER_FROM_CART_ITEM:
        return dispatch(setCartTotal(subTotal, tax, total));
      default:
        break;
    }
  };
};

export const getOtgApiTotalsMiddleware = store => next => async action => {
  next(action);
  const state = await store.getState();
  const { dispatch } = store;
  const cartItems = cartItemsSelector(state);
  const cartTotal = cartTotalSelector(state);
  if (hasCartItems(cartItems)) {
    await dispatch(getTotalsFromApi(cartItems, action));
  } else {
    if (hasPositiveTotal(cartTotal)) {
      dispatch(resetTotals(action));
    } else {
      await dispatch(getTotalsFromApi(cartItems, action));
    }
  }
};
