import * as actions from "./redux/actions";

import React, { memo } from "react";
import { Route, Switch } from "react-router-dom"; //, useLocation
import {
  airportSelector,
  getairportCodeSelector,
  terminalConcourseSelector,
} from "./redux/Admin/admin.selectors";

// import Cart from "./screens/Cart";
// import Categories from "./screens/Categories";
import ConnectedWithRoutes from "./hocs/ConnectedWithRoutes.js";
// import Faq from "../src/components/Faq";
// import Loader from "./common/Loader/Loader";
// import MenuItems from "./screens/MenuItems";
// import OrderComplete from "./screens/OrderComplete";
// import Restaurants from "../src/screens/Restaurants";
// import liveCode from "../src/api/offlineMode";
// import queryString from "query-string";
import { redirectUrlSelector } from "./redux/Payeezy/payeezy.selectors";
import styled from "styled-components";
import tinyURLRedirect from "./screens/TinyUrlRedirect";

const RouterContainer = styled.div`
  height: ${window.innerHeight}px;
  margin-left: auto;
  margin-right: auto;
  max-height: ${window.innerHeight}px;
  max-width: 1200px;
  position: relative;
  width: ${window.innerWidth}px;
`;

const router = memo(function router() {
// {
// airportCode,
// redirectUrl,
// airport,
// terminal
// }
  return (
    <RouterContainer>
      <Switch>
        <Route component={tinyURLRedirect} />
      </Switch>
    </RouterContainer>
  );

  // if (airportCode) {
  //   return (
  //     <RouterContainer>
  //       <Switch>
  //         {liveCode && <Route exact path="/" component={Restaurants} />}
  //         <Route path="/cart" component={Cart} />
  //         <Route path="/order-complete" component={OrderComplete} />
  //         <Route path="/order-failed" component={OrderComplete} />
  //         <Route path="/categories" component={Categories} />
  //         <Route path="/category/:category" component={MenuItems} />
  //         <Route path="/faq" component={Faq} />
  //         <Route
  //           path="/checkout"
  //           component={() => {
  //             window.location = redirectUrl;
  //             return <Loader message="Redirecting ..." />;
  //           }}
  //         />
  //         <Route component={tinyURLRedirect} />
  //       </Switch>
  //     </RouterContainer>
  //   );
  // } else if (airport && terminal) {
  //   console.log(
  //     "return categories because we already set the airport and terminal"
  //   );
  //   return (
  //     <RouterContainer>
  //       <Route component={Categories} />
  //     </RouterContainer>
  //   );
  // } else {
  //   return (
  //     <RouterContainer>
  //       <Switch>
  //         <Route exact path="/" component={Restaurants} />
  //         <Route path="/categories" component={Restaurants} />
  //         <Route path="/cart" component={Restaurants} />
  //         <Route path="/order-complete" component={OrderComplete} />
  //         <Route path="/order-failed" component={OrderComplete} />
  //         <Route path="/faq" component={Faq} />
  //         <Route component={tinyURLRedirect} />
  //       </Switch>
  //     </RouterContainer>
  //   );
  // }
});

const mapStateToProps = (state) => ({
  redirectUrl: redirectUrlSelector(state),
  airportCode: getairportCodeSelector(state),
  airport: airportSelector(state),
  terminal: terminalConcourseSelector(state),
});

export default ConnectedWithRoutes(mapStateToProps, actions)(router);
