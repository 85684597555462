import { createSelector } from "reselect";

export const loadingMessageSelector = state => state.api.loadingMessage;

export const menuItemsSelector = state => state.api.apiItems;

export const menuItemsByIdSelector = createSelector(
  menuItemsSelector,
  items => {
    const itemsObject = {};
    items.map(item => (itemsObject[item.id] = { ...item }));
    return itemsObject;
  }
);

export const modifierCollectionSelector = state =>
  state.api.apiModifierCollections;

export const modifierCollectionByIdSelector = createSelector(
  modifierCollectionSelector,
  modifierCollections => {
    const collectionsObject = {};
    modifierCollections.map(
      collection => (collectionsObject[collection.id] = { ...collection })
    );
    return collectionsObject;
  }
);

export const modifierGroupsSelector = state => state.api.apiModifierGroups;

export const modifierGroupsByIdSelector = createSelector(
  modifierGroupsSelector,
  modifierGroups => {
    const groupsObject = {};
    modifierGroups.map(group => (groupsObject[group.id] = { ...group }));
    return groupsObject;
  }
);

const modifierGroupsByCollectionId = (state, props) => {
  const modifierGroupsByCollectionId = modifierCollectionByIdSelector(state);
  if (
    modifierGroupsByCollectionId[props.modifierCollectionId] &&
    modifierGroupsByCollectionId[props.modifierCollectionId].groups
  ) {
    return modifierGroupsByCollectionId[props.modifierCollectionId].groups;
  }
  return [];
};

const currentModifierCollectionId = (state, props) => {
  return props.modifierCollectionId;
};

export const modifierOptionsSelector = state =>
  state.api.apiItemModifierOptions;

export const modifierOptionsModalSelector = createSelector(
  modifierOptionsSelector,
  options => {
    return options.map(option => {
      const newOption = {
        ...option,
        displayPrice: `${option.price.toFixed(2)}`,
        displayPriceInMiles: "0",
        menuItemId: option.id,
        menuItemPrice: `${option.price.toFixed(2)}`,
        quantity: 1,
        selectionType: option.selectionType.toUpperCase()
      };
      delete newOption.price;
      delete newOption.id;
      return newOption;
    });
  }
);

export const modifierOptionsByIdSelector = createSelector(
  modifierOptionsSelector,
  modifierOptions => {
    const optionsObject = {};
    modifierOptions.map(option => (optionsObject[option.id] = { ...option }));
    return optionsObject;
  }
);

export const makeModifierGroups = () => {
  return createSelector(
    [
      modifierGroupsByIdSelector,
      modifierOptionsByIdSelector,
      modifierGroupsByCollectionId,
      currentModifierCollectionId
    ],
    (
      modifierGroupsById,
      modifierOptions,
      modifierCollection,
      modifierCollectionId
    ) => {
      const modifierGroups = modifierCollection.map(
        group => modifierGroupsById[group]
      );
      const groupsWithOptions = modifierGroups.map(group => {
        const items = group.items.map(itemId => {
          const item = modifierOptions[itemId];
          const itemObject = {
            ...item,
            displayPrice: `${item.price.toFixed(2)}`,
            displayPriceInMiles: "0",
            menuItemId: item.id,
            menuItemPrice: `${item.price.toFixed(2)}`,
            modifierCollectionId: modifierCollectionId,
            quantity: 1,
            selected: item.selectedByDefault,
            selectionType: item.selectionType.toUpperCase()
          };
          delete itemObject.id;
          delete itemObject.price;
          delete itemObject.showTitle;
          return itemObject;
        });
        const groupsWithItems = {
          ...group,
          maximumSelections: 1000,
          minimumSelections: 0,
          selectionType: group.selectionType.toUpperCase(),
          modifierItems: items
        };
        delete groupsWithItems.items;
        return groupsWithItems;
      });
      return groupsWithOptions;
    }
  );
};

export const categoriesSelector = state => state.api.categories;

export const apiCategoriesSelector = state => state.api.apiCategories;

export const itemsByCategorySelector = state => state.api.itemsByCategory;

// selectors for displaying from api data

export const selectedCategorySelector = state =>
  state.selected.selectedCategory;

export const categoryItemsSelector = createSelector(
  itemsByCategorySelector,
  selectedCategorySelector,
  (items, category) => (items && category !== "" ? items[category] : "")
);

export const loadingSelector = state => state.api.loading;

export const apiErrorSelector = state => state.api.apiError;
