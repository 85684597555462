import { createSelector } from "reselect";

import { cartItemsSelector } from "../Cart/cart.selectors";

export const selectedCartItemIndex = state => state.selected.selectedCartIndex;

export const selectedCartItemSelector = () =>
  createSelector(
    cartItemsSelector,
    selectedCartItemIndex,
    (cartItems, index) => cartItems[index]
  );

export const selectedItemSelector = (state, props) => {
  const {
    cartItemIndex,
    description,
    id,
    image,
    location,
    modifierCollectionId,
    price,
    title
  } = props;
  const isCartRoute = location => location === "/cart";
  const itemType = isCartRoute(location) ? "cart" : "menuItem";
  const selectedItem = {
    airlineMiles: undefined,
    description,
    displayPrice: Number(price),
    displayPriceInMiles: 0,
    id,
    image,
    imageUrl: image,
    index: cartItemIndex,
    itemType,
    menuItemId: id,
    menuItemPrice: Number(price),
    modifierCollectionId: modifierCollectionId,
    price: Number(price),
    quantity: 1,
    title
  };
  return selectedItem;
};

export const makeSelectedItem = () => {
  return createSelector(
    [selectedItemSelector],
    selectedItem => selectedItem
  );
};
