import React, { memo } from "react";

// import Description from "../../../atoms/Description";
import ModifierGroups from "../molecules/ModifierGroups";

const styles = {
  scrollView: {
    flex: 1,
    height: "100%",
    maxHeight: 640,
    overflow: "auto",
    width: "100%"
  },
  view: {
    flex: 1,
    height: "100%",
    maxHeight: "100%",
    width: "100%"
  }
};

const Modifiers = memo(function Modifiers({
  // description,
  hasModifiers,
  modifierGroups,
  modifierOptions,
  paymentType,
  selectedItem
}) {
  // const conditionalStyle = hasModifiers ? styles.scrollView : styles.view;
  return (
    hasModifiers && (
      <div style={styles.scrollView}>
        <ModifierGroups
          hasModifiers={hasModifiers}
          modifierGroups={modifierGroups}
          modifierOptions={modifierOptions}
          paymentType={paymentType}
          selectedItem={selectedItem}
        />
      </div>
    )
  );
});

// <Description
// description={description}
// hasModifiers={hasModifiers}
// showCondition={false}
// />

export default Modifiers;
