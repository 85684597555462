// const blue = "#007aff";

const styles = {
  button: {
    backgroundColor: "#ff6900",
    height: 56,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px"
  },
  buttonText: {
    color: "#fff",
    fontFamily: "Gibson-Medium",
    fontSize: "16px",
    fontWeight: "500",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  }
};

export default styles;
