export const modalOpenSelector = state => state.login.modalOpen;
export const errorModalOpenSelector = state => state.login.errorModalOpen;
export const emailFieldSelector = state => state.login.email;
export const errorMessagesSelector = state => state.login.errorMessages;
export const firstNameFieldSelector = state => state.login.firstName;
export const lastNameFieldSelector = state => state.login.lastName;
export const flightNumberSelector = state => state.login.flightNumber;
export const seatNumberSelector = state => state.login.seatNumber;
export const loggedInSelector = state => state.login.loggedIn;
export const paymentModalSelector = state => state.login.paymentModalOpen;
export const cardTypeSelector = state => state.login.cardType;
export const cardNumberSelector = state => state.login.cardNumber;
export const cardExpirySelector = state => state.login.expiry;
export const cardCvcSelector = state => state.login.cvc;
export const pickupDetailsModalSelector = state =>
  state.login.pickupDetailsOpen;
export const flightDateSelector = state => state.login.flightDate;
export const flightTimeSelector = state => state.login.flightTime;
export const validPickupDetailsSelector = state =>
  state.login.validPickupDetails;
export const validPaymentInfoSelector = state => state.login.validPaymentInfo;
