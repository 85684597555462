import moment from 'moment';

import * as types from '../../redux/Payeezy/payeezy.types';

import {
  cartItemsSelector,
  cartTotalSelector,
  fixedSubtotalSelector,
  fixedTaxSelector
} from '../../redux/Cart/cart.selectors';
import {
  flightTimeSelector,
  flightDateSelector
} from '../../redux/Login/login.selectors';
import { airportCodeSelector } from '../../redux/Admin/admin.selectors';
import host, { uriScheme } from '../../api/host';

const { hasId, hasItemsInCart } = {
  hasId: (selectedModifiers, menuItemId) => selectedModifiers.has(menuItemId),
  hasItemsInCart: cartItems => cartItems && cartItems.length > 0
};

export const convertItems = cartItems => {
  return hasItemsInCart(cartItems)
    ? cartItems.map(item => {
        const { menuItemId, menuItemPrice, quantity } = item;
        const selectedModifiers = new Set(item.selectedModifiers);
        const modifiers = createPapiModifiers(item, selectedModifiers);
        return {
          menu_item_id: menuItemId,
          menu_item_price: Number(menuItemPrice),
          modifiers,
          quantity
        };
      })
    : [];
};

const createPapiModifiers = ({ modifierGroups }, selectedModifiers) => {
  const papiModifiers = [];
  modifierGroups.map(({ modifierItems }) =>
    modifierItems.map(({ menuItemId, menuItemPrice }) => {
      if (hasId(selectedModifiers, menuItemId)) {
        const option = {
          menu_item_id: menuItemId,
          menu_item_price: Number(menuItemPrice),
          quantity: 1
        };
        papiModifiers.push(option);
      }
    })
  );
  return papiModifiers;
};

const translateFlightDate = date => {
  switch (date) {
    case 'Today':
      return moment(new Date()).format('ddd, MMM D');
    // case "ASAP":
    //   return moment(new Date()).format("ddd, MMM D");
    // case "Later Today":
    //   return moment(new Date()).format("ddd, MMM D");
    case 'Tomorrow':
      return moment(new Date())
        .add(1, 'days')
        .format('ddd, MMM D');
    default:
      return date;
  }
};

const createDateTime = (flightDate, flightTime) => {
  const dateTimeString = moment(
    `${flightDate} ${flightTime}`,
    'ddd, MMM D hh:mm a'
  );
  const ISOdateTime = moment.utc(dateTimeString).toISOString();
  return ISOdateTime;
};

function formatPapiData(dateTime, items) {
  return {
    date: dateTime,
    moniker: '',
    items: items
  };
}

export const createPapiData = data => {
  const { date, moniker, items } = data;
  return {
    deferredInfo: {
      name: '',
      email: '',
      date: date
    },
    tip_amount: '0.00',
    moniker: moniker,
    order_type: 'TOGO',
    payment_type: 'PAYEEZY',
    items
  };
};

export const preparePapiData = async cart => {
  const { cartItems, flightTime, flightDate } = cart;
  const translatedDate = translateFlightDate(flightDate);
  const dateTime = createDateTime(translatedDate, flightTime);
  const items = convertItems(cartItems);
  const data = formatPapiData(dateTime, items);
  const papiData = await createPapiData(data);
  return papiData;
};

const getOptions = body => {
  return {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      cache: 'no-cache',
      credentials: 'include'
    },
    method: 'POST',
    mode: 'cors'
  };
};

async function savePapiDataToFirebase({ airportCode, cart, papiData }) {
  const url = uriScheme + host + '/pending';
  const body = { airportCode, cart, papiData };
  const options = getOptions(body);
  const orderId = await fetch(url, options);
  return orderId;
}

export const handlePaymentDetailsSubmit = (event) /*document*/ => {
  return async (dispatch, getState) => {
    event.preventDefault();
    const state = await getState();
    const airportCode = airportCodeSelector(state);
    const subTotal = fixedSubtotalSelector(state);
    const tax = fixedTaxSelector(state);
    const total = cartTotalSelector(state);
    const totals = {
      subTotal,
      tax,
      total
    };
    const cart = {
      amount: total,
      cartItems: cartItemsSelector(state),
      flightDate: flightDateSelector(state),
      flightTime: flightTimeSelector(state),
      totals
    };
    await dispatch(togglePayButtonLoading(true));
    const papiData = await preparePapiData(cart);
    const response = await savePapiDataToFirebase({
      airportCode,
      cart,
      papiData
    });
    const orderId = await response.json();
    dispatch(setInvoiceNum(orderId));
    document.getElementById('pay_now_form').submit();
  };
};

export const togglePayButtonLoading = loading => ({
  type: types.TOGGLE_PAY_BUTTON_LOADING,
  loading
});

export const updatePaymentErrorMessage = message => ({
  type: types.UPDATE_PAYMENT_ERROR_MESSAGE,
  message
});

export const setInvoiceNum = xInvoiceNum => ({
  type: types.SET_INVOICE_NUM,
  xInvoiceNum
});
