import { bindActionCreators } from "./helpers";
import { types } from "./reducer";
import store from "./store";
import { selectedModifiersSelector } from "./selectors";

export const toggleItemModal = (itemModalOpen, selectedItem) => {
  return dispatch => {
    dispatch(clearSelectedItem());
    const { isClosing, isCartOrMenuItem } = {
      isCartOrMenuItem: selectedItem => selectedItem.itemType === "cart",
      isClosing: itemModalOpen => itemModalOpen === false
    };
    if (isClosing(itemModalOpen)) {
      dispatch({ type: types.TOGGLE_ITEM_MODAL, itemModalOpen: false });
      return;
    }
    dispatch(handleSelectItem(selectedItem));
    if (isCartOrMenuItem(selectedItem)) {
      dispatch(
        addSavedPropsToStore(
          selectedItem.selectedChecks,
          selectedItem.selectedGroupCounts,
          selectedItem.selectedItemId,
          selectedItem.selectedModifiers,
          selectedItem.selectedRadios,
          selectedItem.cartItemIndex
        )
      );
    } else {
      dispatch(setDefaultModifierCounts(selectedItem));
      dispatch(sortDefaultChecksAndRadios(selectedItem));
      dispatch(createSelectedUniqueId(selectedItem));
    }
    dispatch({ type: types.TOGGLE_ITEM_MODAL, itemModalOpen });
  };
};

export const addSavedPropsToStore = (
  selectedChecks,
  selectedGroupCounts,
  selectedItemId,
  selectedModifiers,
  selectedRadios,
  cartItemIndex
) => ({
  type: types.ADD_SAVED_PROPS_TO_STORE,
  selectedChecks,
  selectedGroupCounts,
  selectedItemId,
  selectedModifiers,
  selectedRadios,
  cartItemIndex
});

export const updateAppWidth = (appWidth, appHeight) => ({
  type: types.UPDATE_APP_WIDTH,
  appWidth: appWidth !== undefined ? appWidth : window.outerWidth,
  appHeight: appHeight !== undefined ? appHeight : window.outerHeight
});

export const clearSelectedItem = () => ({
  type: types.CLEAR_SELECTED_ITEM
});

export const cancelAddToCart = () => ({
  type: types.TOGGLE_ITEM_MODAL,
  itemModalOpen: false
});

export const incrementSelectedQuantity = sign => ({
  type: types.INCREMENT_SELECTED_QUANTITY,
  sign
});

export const handleSelectItem = selectedItem => ({
  type: types.HANDLE_SELECT_ITEM,
  selectedItem
});

export const setDefaultModifierCounts = selectedItem => {
  return dispatch => {
    const counts = {};
    selectedItem.modifierGroups.map(group => {
      let count = 1;
      counts[group.id] = 0;
      group.modifierItems.map(option => {
        if (
          selectedItem &&
          selectedItem.selectedModifiers &&
          selectedItem.selectedModifiers.length > 0 &&
          selectedItem.selectedModifiers.includes(option.menuItemId)
        ) {
          counts[group.id] = count++;
        }
      });
    });
    dispatch({ type: types.SET_DEFAULT_MAX_MIN_COUNTS, counts });
  };
};

export const addDefaultCheckboxAndRadioModifiers = ({
  checkIds,
  radioIds,
  selectedModifiers
}) => ({
  type: types.ADD_DEFAULT_CHECKBOX_AND_RADIO_MODIFIERS,
  checkIds,
  radioIds,
  selectedModifiers
});

const isCheckbox = selectionType => {
  return selectionType === "CHECKBOX";
};

const isSelectedModifier = (selectedModifiers, menuItemId) => {
  if (selectedModifiers && selectedModifiers.length > 0) {
    return selectedModifiers.includes(menuItemId);
  } else {
    return false;
  }
};

export const sortDefaultChecksAndRadios = selectedItem => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedModifiers = selectedModifiersSelector(state);
    const { modifierGroups } = selectedItem;
    const checkIds = [];
    const radioIds = {};
    modifierGroups.map(group => {
      const { modifierItems, id } = group;
      modifierItems.map(option => {
        const { menuItemId, selectionType } = option;
        if (isSelectedModifier(selectedModifiers, menuItemId)) {
          isCheckbox(selectionType)
            ? checkIds.push(menuItemId)
            : (radioIds[id] = menuItemId);
        }
      });
    });
    dispatch(
      addDefaultCheckboxAndRadioModifiers({
        checkIds,
        radioIds,
        selectedModifiers
      })
    );
  };
};

export const addSelectedModifier = ({ optionId }) => ({
  type: types.ADD_SELECTED_ITEM_MODIFIER,
  optionId
});

export const addSelectedCheckboxModifier = ({ optionId }) => ({
  type: types.ADD_SELECTED_CHECKBOX_MODIFIER,
  optionId
});

export const updateMaxMinModifiersCount = ({ sign, groupId }) => ({
  type: types.UPDATE_MAX_MIN_MODIFIERS_COUNT,
  sign,
  groupId
});

export const updateSelectedRadioModifier = ({ groupId, optionId }) => ({
  type: types.UPDATE_SELECTED_RADIO_MODIFIER,
  groupId,
  optionId
});

export const removeSelectedModifier = ({ optionId }) => ({
  type: types.REMOVE_SELECTED_ITEM_MODIFIER,
  optionId
});

export const removeSelectedCheckboxModifier = ({ optionId }) => ({
  type: types.REMOVE_SELECTED_CHECKBOX_MODIFIER,
  optionId
});

export const toggleCheckBox = ({
  optionId,
  groupId,
  isSelected,
  selectedItem
}) => {
  return dispatch => {
    if (isSelected) {
      dispatch(removeSelectedCheckboxModifier({ optionId }));
      dispatch(removeSelectedModifier({ optionId }));
      dispatch(updateMaxMinModifiersCount({ sign: "subtract", groupId }));
    } else {
      dispatch(addSelectedCheckboxModifier({ optionId }));
      dispatch(addSelectedModifier({ optionId }));
      dispatch(updateMaxMinModifiersCount({ sign: "add", groupId }));
    }
    dispatch(createSelectedUniqueId(selectedItem));
  };
};

export const toggleRadioButton = ({
  groupId,
  isSelected,
  optionId,
  selectedItem,
  selectedRadios
}) => {
  return dispatch => {
    if (isSelected) {
      dispatch(updateSelectedRadioModifier({ groupId, optionId: null }));
      dispatch(removeSelectedModifier({ optionId: selectedRadios[groupId] }));
      dispatch(updateMaxMinModifiersCount({ sign: "subtract", groupId }));
    } else {
      dispatch(updateSelectedRadioModifier({ groupId, optionId }));
      dispatch(removeSelectedModifier({ optionId: selectedRadios[groupId] }));
      dispatch(addSelectedModifier({ optionId }));
      dispatch(updateMaxMinModifiersCount({ sign: "add", groupId }));
    }
    dispatch(createSelectedUniqueId(selectedItem));
  };
};

export const createSelectedUniqueId = selectedItem => {
  return (dispatch, getState) => {
    let uniqueId;
    const state = getState();
    let modifierIds = selectedModifiersSelector(state);
    modifierIds = modifierIds.sort((a, b) => a - b);
    if (modifierIds.length > 0) {
      uniqueId = selectedItem.menuItemId + "-" + modifierIds.join("-");
    } else {
      uniqueId = selectedItem.menuItemId;
    }
    dispatch({ type: types.UPDATE_SELECTED_UNIQUE_ID, uniqueId });
  };
};

const actions = {
  addSelectedCheckboxModifier,
  addSelectedModifier,
  cancelAddToCart,
  clearSelectedItem,
  createSelectedUniqueId,
  incrementSelectedQuantity,
  removeSelectedCheckboxModifier,
  removeSelectedModifier,
  toggleCheckBox,
  toggleItemModal,
  toggleRadioButton,
  updateMaxMinModifiersCount,
  updateSelectedRadioModifier
};

const boundActions = bindActionCreators(actions, store);

export default boundActions;
