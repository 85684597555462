import React from "react";

const separator = {
  borderTop: "1px solid #C7C7C7",
  height: 1,
  marginBottom: 13,
  marginLeft: 13,
  marginRight: 13,
  marginTop: 13
};

const { shouldShow } = {
  shouldShow: (hasModifiers, showCondition) => hasModifiers === showCondition
};
// const { displaySeparator, isAny, shouldShowByOrientation } = {
//   displaySeparator: (showOrientation, orientation) =>
//     isAny(showOrientation) ||
//     shouldShowByOrientation(showOrientation, orientation),
//   isAny: showOrientation => showOrientation === "any",
//   shouldShowByOrientation: (showOrientation, orientation) =>
//     showOrientation === orientation
// };

// const Separator = ({ orientation, showOrientation }) => {
//   return isAny(showOrientation) ||
//     displaySeparator(orientation, showOrientation) ? (
//     <div style={separator} />
//   ) : null;
// };

const Separator = ({ both, hasModifiers, showCondition }) => {
  return both || shouldShow(hasModifiers, showCondition) ? (
    <div style={separator} />
  ) : null;
};

export default Separator;
