import './polyfills';

// import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ErrorBoundary from 'react-error-boundary';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import configureStore from './redux/store';
import ErrorMessage from './error';
// import Loader from './common/Loader/Loader';

import './index.css';

const { store, history } = configureStore();

const rootElement = document.getElementById('root');

const Setup = Component => (
  <ErrorBoundary FallbackComponent={ErrorMessage}>
    <Provider store={store}>
      {/* <PersistGate loading={<Loader />} persistor={persistor}> */}
      <Router history={history}>
        <Component />
      </Router>
      {/* </PersistGate> */}
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(Setup(App), rootElement);

serviceWorker.unregister();
