export const spacesToDashes = str =>
  str
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase();
export const dashToHeading = str => str.replace(/-/g, " ").toUpperCase();
export const dashToSpaces = str => str.replace(/-/g, " ").toLowerCase();
export const dashToCamel = str =>
  str.replace(/(-[a-z])/g, val => val.toUpperCase().replace("-", ""));
export const url = "http://s3.amazonaws.com/otg-pp-demo-dev/images/";
