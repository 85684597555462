// import LogRocket from 'logrocket';
import React, { Component } from "react";

import ConnectedWithRoutes from "../hocs/ConnectedWithRoutes";
import Img from "react-image";
import Loader from "../common/Loader/Loader";
import { TouchableOpacity } from "react-native-web";
import emptyPlate from "../images/empty-plate-icon@3x.svg";
import { resolveTinyURL } from "../redux/Api/api.actions";
import styled from "styled-components";
import urlHelperImage from "../images/tinyUrlHelper@3x.png";

const { doneLoading } = {
  doneLoading: (loading) => loading === false,
};

class TinyUrlRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      codeEntryValue: "",
      shouldShowImage: false,
      tinyCode: this.props.location.pathname,
      invalidCode: false,
    };
  }
  componentDidMount = async () => {
    this.loadNewCode(this.state.tinyCode);
  };

  loadNewCode = async (tinyCodePath) => {
    console.log("tinyURL: ", tinyCodePath);
    const result = await resolveTinyURL(tinyCodePath.toUpperCase());
    console.log("redirect request response: ", result);
    if (result.didSucceed && result.data.success) {
      console.log("redirect url: ", result.data.data.square_url);
      window.location = result.data.data.square_url;
    } else {
      this.toggleLoading();
    }
  };

  toggleLoading = () => this.setState({ loading: !this.state.loading });

  updateProperties = (properties) => this.setState({ properties });

  render() {
    const codeChange = (event) => {
      const alpha = /[A-Za-z0123456789]/;
      const newCode = event.target.value.toUpperCase();
      if (alpha.test(newCode) && newCode.length == 4) {
        // console.log("code complete", event.target.value);
        this.setState({
          codeEntryValue: newCode,
          tinyCode: "/" + newCode,
          invalidCode: false,
          loading: !this.state.loading,
        });
        this.loadNewCode("/" + newCode);
      } else if (
        alpha.test(newCode) &&
        newCode.length > 0 &&
        newCode.length < 4
      ) {
        // console.log("new code", event.target.value);
        this.setState({ codeEntryValue: newCode, invalidCode: false });
      } else if (newCode.length === 0) {
        // console.log("empty code");
        this.setState({ codeEntryValue: "", invalidCode: false });
      } else {
        // console.log(
        //   "bad code entered: ",
        //   event.target.value,
        //   " event.target content: ",
        //   event.target
        // );
        this.setState({ invalidCode: true });
      }
    };
    const showCodeLocation = () => {
      this.setState({ shouldShowImage: !this.state.shouldShowImage });
    };

    const {
      loading,
      codeEntryValue,
      shouldShowImage,
      invalidCode,
    } = this.state;
    // console.log(
    //   "redirect render vars: ",
    //   loading,
    //   codeEntryValue,
    //   shouldShowImage,
    //   this.state.tinyCode,
    //   invalidCode
    // );
    return doneLoading(loading) ? (
      <Container>
        <ErrorImage src={emptyPlate} />
        <TitleText>Oops...</TitleText>
        <Text>
          We were unable to locate your table or seat using the url you typed
          in. Can you re-enter the 4 digit code below?
        </Text>
        <CodeContainer>
          <CodeText>
            <BoldSection>OTG</BoldSection>
            <NormalSection>ORDER.COM/</NormalSection>
          </CodeText>
          <CodeEntryField
            id={1}
            type="text"
            value={codeEntryValue}
            onChange={codeChange}
            invalid={invalidCode}
          />
        </CodeContainer>
        <TouchableOpacity onClick={showCodeLocation}>
          <CodeButton>Wheres my code?</CodeButton>
        </TouchableOpacity>
        {shouldShowImage ? <CodeImage src={urlHelperImage} /> : null}
      </Container>
    ) : (
      <Loader />
    );
  }
}

export default ConnectedWithRoutes(null, null)(TinyUrlRedirect);

// const inputBorder = ({ invalid }) => {
//   const borderStyle = invalid ? "2px solid #ebd834" : "1px solid #afafaf";
//   return borderStyle;
// };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 15px;
  align-items: center;
`;
const ErrorImage = styled.img.attrs((props) => ({
  src: props.src,
  loader: props.loader,
}))`
  width: 272px;
  height: 194px;
`;
const TitleText = styled.div`
  width: 102px;
  height: 36px;
  color: rgb(54, 54, 54);
  font-size: 30px;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
  padding: 18px 0px;
`;
const Text = styled.div`
  width: 343px;
  height: 100px;
  color: rgb(84, 84, 84);
  font-size: 20px;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: center;
`;

const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  height: 54px;
  width: 343px;
  background: #ff6900;
  border-radius: 4px;
  align-content: center;
  justify-content: center;
  margin-top: 18px;
`;
const CodeText = styled.div`
  justify-content: center;
  align-content: center;
  padding-top: 7.5px;
  padding-right: 2px;
  height: 29px;
`;
const BoldSection = styled.span`
  color: #ffffff;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0px;
`;

const NormalSection = styled.span`
  color: #ffffff;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0px;
`;

const CodeEntryField = styled.input.attrs((props) => ({
  id: props.id,
  type: props.type,
  placeholder: props.placeholder,
  value: props.value,
  onChange: props.onChange,
  invalid: props.invalid,
}))`
  height: 40px;
  border-radius: 4px;
  border: 1px solid #afafaf;
  width: 110px;
  text-align: center;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
`;
const CodeButton = styled.div`
  text-align: center;
  text-decoration: underline;
  height: 24px;
  color: rgb(84, 84, 84);
  font-size: 20px;
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
  letter-spacing: 0px;
  margin: 8px 0px;
`;
// border: ${(props) => props.invalid ? "2px solid #ebd834" : "1px solid #afafaf"}; //commented out because I couldn't find a good highlight color on the orange -ct

const CodeImage = styled(Img).attrs((props) => ({
  src: props.src,
  loader: props.loader,
}))`
  width: 343px;
  height: 199px;
`;
