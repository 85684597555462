import RootReducer, { createReduxHistory, routerMiddleware } from "./reducers";
import { applyMiddleware, createStore } from "redux";

import { getOtgApiTotalsMiddleware } from "./Cart/cart.actions";
import thunk from "redux-thunk";

// import { persistStore } from "redux-persist";


const isDevelopment = () => process.env.NODE_ENV === "development";

const middleware = [thunk, routerMiddleware, getOtgApiTotalsMiddleware];

if (isDevelopment()) {
  const logger = require("redux-logger").default;
  middleware.push(logger);
} else {
  // const LogRocket = require('logrocket');
  // middleware.push(LogRocket.reduxMiddleware());
  // LogRocket.init('wujanq/order-ahead-odin');
}

const enhancer = applyMiddleware(...middleware);

export default () => {
  const store = createStore(RootReducer, undefined, enhancer);
  // const persistor = persistStore(store, enhancer, () => {});
  const history = createReduxHistory(store);
  return { store, history };
};
