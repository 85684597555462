import React, { memo } from "react";
import { Text, View } from "react-native";
import { isCheckOrRadio, price, removePrice } from "./ModifierOption.logic";

import CheckBox from "../../atoms/CheckBox";
import RadioButton from "../../atoms/RadioButton";
import { generate as key } from "shortid";
import styles from "./ModifierOption.style";

const ModifierOption = memo(function ModifierOption({
  group,
  groupIndex,
  modifierGroups,
  modifierOptions,
  option,
  optionIndex,
  paymentType,
  selectedItem
}) {
  const { selectionType, id, minimumSelections, maximumSelections } = group;
  let {
    title,
    menuItemId,
    menuItemPrice,
    airlineMiles,
    displayPriceInMiles
  } = option;
  title = removePrice(title);
  return (
    <View key={key()} style={styles.optionContainer}>
      <Text style={styles.optionText}>{title}</Text>
      <View style={styles.optionPriceContainer}>
        <Text style={styles.optionText}>
          {price({
            paymentType,
            menuItemPrice,
            airlineMiles,
            displayPriceInMiles
          })}
        </Text>
        {isCheckOrRadio(selectionType, minimumSelections, maximumSelections) ? (
          <CheckBox
            groupId={id}
            groupIndex={groupIndex}
            maximumSelections={maximumSelections}
            minimumSelections={minimumSelections}
            modifierGroups={modifierGroups}
            modifierOptions={modifierOptions}
            optionId={menuItemId}
            optionIndex={optionIndex}
            selectedItem={selectedItem}
            style={styles.checkbox}
          />
        ) : (
          <RadioButton
            groupId={id}
            modifierOptions={modifierOptions}
            optionId={menuItemId}
            selectedItem={selectedItem}
            style={styles.radioButton}
          />
        )}
      </View>
    </View>
  );
});

export default ModifierOption;
