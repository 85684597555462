import React, { memo } from "react";

const groupName = {
  marginTop: 13,
  marginBottom: 8,
  color: "#363636",
  fontFamily: "Gibson-Regular",
  fontSize: "18px",
  fontWeight: "normal",
  letterSpacing: "0.4px",
  paddingTop: 13,
  paddingLeft: 13,
  paddingRight: 13
};

const GroupTitle = memo(function GroupTitle({ title }) {
  return <div style={groupName}>{title}</div>;
});

export default GroupTitle;
