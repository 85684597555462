import { connect } from "react-redux";

import { withRouter } from "react-router";

function ConnectedWithRoutes(mapStateToProps, mapDispatchToProps) {
  return WrappedComponent =>
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(WrappedComponent)
    );
}

export default ConnectedWithRoutes;
