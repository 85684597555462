import React, { memo } from "react";

// import loader from "../../images/cubeLoader.svg";
import PlaneLoader from "react-loader-spinner";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  background: #ece9e6;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
`;

// const Container = styled.div`
//   align-items: center;
//   /* background: -webkit-linear-gradient(to right, #ffffff, #ece9e6); */
//   background: #ece9e6;
//   /* background: linear-gradient(to right, #ffffff, #ece9e6); */
//   background-size: cover;
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   height: 100vh;
//   justify-content: center;
//   width: 100%;
//   padding: 0;
//   margin: 0;
// `;

// const Image = styled.img.attrs(props => ({
//   rel: props.rel,
//   src: props.src
// }))`
//   align-self: center;
//   width: 50%;
//   height: auto;
// `;

const Text = styled.div`
  color: black;
  font-size: 22px;
  margin-top: 10%;
  text-align: center;
  white-space: pre-wrap;
`;

const Loader = memo(function Loader({ message }) {
  return (
    <Container>
      <PlaneLoader
        color="#FF6900"
        width={200}
        height={200}
        type="Plane"
        secondaryColor="grey"
      />
      <Text>{message}</Text>
    </Container>
  );
});

export default Loader;
