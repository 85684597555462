import host, { uriScheme } from "../../api/host";
import liveCode from "../../api/offlineMode";

import { setAirportCode } from "../Admin/admin.actions";
import * as types from "../../redux/Api/api.types";
import categories from "../../api/offlineData/categories.json";
import categoriesArray from "../../api/offlineData/categoriesArray.json";
import items from "../../api/offlineData/items.json";
import itemsByCategory from "../../api/offlineData/itemsByCategory.json";
import modifierCollections from "../../api/offlineData/modifierCollections.json";
import modifierGroups from "../../api/offlineData/modifierGroups.json";
import modifierOptions from "../../api/offlineData/modifierOptions.json";

export const setDataFromApi = data => ({
  type: types.SET_API_DATA,
  data
});

export const setApiError = apiError => ({
  type: types.SET_API_ERROR,
  apiError
});

export const setCategoriesFromApi = categories => ({
  type: types.SET_CATEGORY_DATA,
  categories
});

export const toggleLoading = loading => ({
  type: types.TOGGLE_LOADING,
  loading
});

export const updateMenuLoadingMessage = loadingMessage => ({
  type: types.UPDATE_MENU_LOADING_MESSAGE,
  loadingMessage
});

// async function handleNoData(history, dispatch) {
//   await history.push("/categories");
//   let counter = 6;
//   const countdown = await window.setInterval(async function() {
//     if (counter === 1) {
//       await window.clearInterval(countdown);
//       await dispatch(resetStore());
//       return history.push("/");
//     }
//     await dispatch(
//       updateMenuLoadingMessage(
//         `Failed to Load Categories...\n\n Reloading in ${(counter -= 1)}`
//       )
//     );
//   }, 1000);
// }

const getOptions = airportCode => {
  return {
    body: JSON.stringify(airportCode),
    credentials: "same-origin",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      cache: "no-cache",
      credentials: "include"
    },
    method: "POST",
    mode: "cors"
  };
};

export const resolveTinyURL = async (tinyURLCode) => {
  const url = uriScheme + host + "/tinyurl" + tinyURLCode;
  // console.log("tiny url lookup url: ", url);
  try {
    const response = await fetch(url, {
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        cache: "no-cache",
        credentials: "include",
      },
      method: "GET",
      mode: "cors",
    });
    const json = await response.json();
    // console.log("fetch returned: ", json);
    return { didSucceed: true, data: json };
  } catch (err) {
    // console.log("resolveTinyURL err:", err);
    return { didSucceed: false, data: err };
  }
};

export const getUserCartFromDatabase = async (airportCode, orderId) => {
  const url = uriScheme + host + "/getCart";
  const body = { airportCode, orderId };
  const options = getOptions(body);
  try {
    const response = await fetch(url, options);
    const json = await response.json();
    return json;
  } catch (err) {
    console.log("getUserCartFromDatabase err:", err);
  }
};

async function getMenuDataFromFirebase(airportCode) {
  const url = uriScheme + host + "/getMenu";
  const options = getOptions({ airportCode });
  try {
    const data = await fetch(url, options);
    return data;
  } catch (err) {
    console.log("getMenuDataFromFirebase err:", err);
  }
}

export const getDataFromAPI = (liveCode, host, history) => {
  return async dispatch => {
    let data;
    if (liveCode) {
      data = await getMenuDataFromFirebase(host);
      data = await data.json();
      const hasData = data => data !== undefined;
      if (hasData(data)) {
        history.push("/categories");
        await dispatch(setDataFromApi(data));
        dispatch(toggleLoading(false));
      } else {
        // throw Error;
        // handleNoData(history, dispatch);
      }
      return data;
    } else {
      data = {
        categories,
        categoriesArray,
        items,
        itemsByCategory,
        modifierCollections,
        modifierGroups,
        modifierOptions
      };
      dispatch(setDataFromApi(data));
      console.log("static data");
    }
  };
};

export const resetStore = () => ({
  type: types.RESET_STORE
});

export const handleFormSubmit = (history, airportCode) => {
  return async dispatch => {
    await dispatch(toggleLoading(true));
    await dispatch(setAirportCode(airportCode));

    dispatch(getDataFromAPI(liveCode, airportCode, history));
  };
};
