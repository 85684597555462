import React, { Fragment, memo } from "react";

import ModifierGroup from "./ModifierGroup";
// import Separator from "../../../atoms/Separator";
import { generate as key } from "shortid";

const ModifierGroups = memo(function ModifierGroups({
  // hasModifiers,
  modifierGroups,
  modifierOptions,
  paymentType,
  selectedItem
}) {
  return (
    <Fragment key={key()}>
      {modifierGroups.map((group, groupIndex) => {
        const { modifierItems, title } = group;
        return (
          <ModifierGroup
            group={group}
            groupIndex={groupIndex}
            key={key()}
            modifierGroups={modifierGroups}
            modifierItems={modifierItems}
            modifierOptions={modifierOptions}
            paymentType={paymentType}
            selectedItem={selectedItem}
            title={title}
          />
        );
      })}
    </Fragment>
  );
});

// <Separator hasModifiers={hasModifiers} showCondition={false} />

export default ModifierGroups;
