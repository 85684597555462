// import moment from "moment";

// import validate from "validate.js";

import * as types from '../../redux/Login/login.types';

import * as actions from '../../redux/actions';

// General Toggles

export const toggleLoggedIn = loggedIn => ({
  type: types.TOGGLE_LOGGED_IN,
  loggedIn
});

export const toggleModalOpen = modalOpen => {
  return async dispatch => {
    if (!modalOpen) {
      dispatch(actions.toggleLoading(false));
    }
    dispatch({ type: types.TOGGLE_MODAL_OPEN, payload: modalOpen });
  };
};

export const toggleErrorModalOpen = errorModalOpen => {
  return async dispatch => {
    if (errorModalOpen) {
      dispatch(actions.toggleLoading(false));
    }
    dispatch({ type: types.TOGGLE_ERROR_MODAL_OPEN, payload: errorModalOpen });
  };
};

// Flight Details Modal

export const toggleValidPickupDetails = validPickupDetails => ({
  type: types.TOGGLE_VALID_FLIGHT_DETAILS,
  validPickupDetails
});

export const togglePickupDetailsModal = pickupDetailsOpen => ({
  type: types.TOGGLE_FLIGHT_DETAILS_MODAL,
  pickupDetailsOpen
});

export const handleFlightNumberChange = event => {
  return async dispatch => {
    const flightNumber = event.target.value;
    if (flightNumber.length <= 13) {
      dispatch({
        type: types.FLIGHT_NUMBER_CHANGE,
        payload: event.target.value
      });
    }
  };
};

export const handleFlightDateChange = ({ value }, initialFlightTimeChange) => {
  let resetTime = {};
  if (initialFlightTimeChange === 0) {
    resetTime = { initialFlightTimeChange: 1, flightTime: '' };
  }

  return {
    type: types.HANDLE_FLIGHT_DATE_CHANGE,
    payload: { flightDate: value, ...resetTime }
  };
};

export const handleFlightTimeChange = ({ value }, initialFlightTimeChange) => {
  let resetTime = {};
  if (initialFlightTimeChange === 0) {
    resetTime = { initialFlightTimeChange: 1 };
  }

  return {
    type: types.HANDLE_FLIGHT_TIME_CHANGE,
    payload: { flightTime: value, ...resetTime }
  };
};

export const handleFlightDateTimeChange = (pickupDate, pickupTime) => {
  return {
    type: types.HANDLE_FLIGHT_DATE_TIME_CHANGE,
    payload: { flightDate: pickupDate, flightTime: pickupTime }
  };
};

export const handlePickupDetailsSubmit = () => {
  // console.log("GOT HERE");
  // event.preventDefault();
  // const { login } = await getState();
  // const dataToValidate = {
  //   flightDate: login.flightDate,
  //   flightTime: login.flightTime
  // };
  // const isValid = await dispatch(
  //   actions.frontEndValidation(dataToValidate, constraints.pickupDetails)
  // );
  // if (isValid) {
  //   console.log("GOT HERE");
  //   // dispatch(actions.toggleLoggedIn(true));
  //   // dispatch(actions.toggleValidPickupDetails(true));
  //   await dispatch(actions.handlePaymentDetailsSubmit(event, history));
  // }
};

// Payment Modal

export const toggleValidPaymentInfo = validPaymentInfo => ({
  type: types.TOGGLE_VALID_PAYMENT_INFO,
  validPaymentInfo
});

export const togglePaymentModal = paymentModalOpen => ({
  type: types.TOGGLE_PAYMENT_MODAL,
  paymentModalOpen
});

export const handleFirstNameChange = event => ({
  type: types.FIRST_NAME_CHANGE,
  payload: event.target.value
});

export const handleLastNameChange = event => ({
  type: types.LAST_NAME_CHANGE,
  payload: event.target.value
});

export const handleEmailChange = event => ({
  type: types.EMAIL_CHANGE,
  payload: event.target.value
});

export const handleCardNumberChange = event => ({
  type: types.HANDLE_CARD_NUMBER_CHANGE,
  payload: event.target.value
});

export const handleCardCvcChange = event => ({
  type: types.HANDLE_CVC_CHANGE,
  payload: event.target.value
});

export const handleCardExpiryChange = event => ({
  type: types.HANDLE_EXPIRY_CHANGE,
  payload: event.target.value
});

export const handleCardTypeChange = event => ({
  type: types.HANDLE_CARD_TYPE_CHANGE,
  payload: event.target.value
});

// Form Validation
// export const frontEndValidation = (dataToValidate, constraints) => {
//   return async dispatch => {
//     dispatch({ type: types.FRONTEND_VALIDATION_SIGNUP });
//     // await extendDateTimeValidation();
//     const errors = await validate(dataToValidate, constraints);
//     if (errors) {
//       const fields = Object.keys(errors) ? Object.keys(errors) : null;
//       const messagesArray = [].concat(...fields.map(field => errors[field]));
//       await dispatch(actions.updateErrorMessages(messagesArray));
//       await dispatch(actions.toggleErrorModalOpen(true));
//       return false;
//     } else {
//       return true;
//     }
//   };
// };

// export const extendDateTimeValidation = () => {
//   validate.extend(validate.validators.datetime, {
//     // The value is guaranteed not to be null or undefined but otherwise it
//     // could be anything.
//     parse: function(value /*, options*/) {
//       return +moment.utc(value);
//     },
//     // Input is a unix timestamp
//     format: function(value, options) {
//       var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
//       return moment.utc(value).format(format);
//     }
//   });
// };

export const updateErrorMessages = errorMessages => ({
  type: types.UPDATE_ERROR_MESSAGE,
  errorMessages
});

// Unused Fields

export const handleSeatNumberChange = event => {
  return async dispatch => {
    const seatNumber = event.target.value;
    if (seatNumber.length <= 3) {
      dispatch({ type: types.SEAT_NUMBER_CHANGE, payload: event.target.value });
    }
  };
};
