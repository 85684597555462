import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { hardSet } from "redux-persist/lib/stateReconciler/hardSet";
import { persistReducer } from "redux-persist";
import createHistory from "history/createBrowserHistory";
import storage from "redux-persist/lib/storage";

import admin from "./Admin/admin.reducer";
import api from "./Api/api.reducer";
import cart from "./Cart/cart.reducer";
import login from "./Login/login.reducer";
import payeezy from "./Payeezy/payeezy.reducer";
import selected from "./Selected/selected.reducer";
import ItemModalReducer from "../common/ItemModal/redux/reducer";

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({
  history: createHistory()
});

const makePersistConfig = (key, blacklist = []) => {
  return persistReducer(
    {
      key,
      storage,
      stateReconciler: hardSet,
      blacklist: blacklist
    },
    key
  );
};

const payeezyBlacklist = [
  "amount",
  "cardNumber",
  "email",
  "errorMessage",
  "firstName",
  "lastName",
  "loading",
  "redirectUrl",
  "type",
  "xResponseCode",
  "xResponseReasonText"
];

const apiBlacklist = ["loadingMessage"];

const AppReducer = combineReducers({
  admin: admin,
  api: makePersistConfig(api, apiBlacklist), // not persisted
  cart,
  login: makePersistConfig(login),
  payeezy: makePersistConfig(payeezy, payeezyBlacklist),
  itemModal: ItemModalReducer,
  selected: makePersistConfig(selected), // not persisted
  router: routerReducer
});

const initialState = AppReducer({}, {});

const resetStore = ({ action }) => {
  const { type } = action;
  return type === "RESET_STORE";
};

const RootReducer = (state, action) => {
  if (resetStore({ action })) {
    state = initialState;
  }
  return AppReducer(state, action);
};

export default RootReducer;
