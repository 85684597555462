// import { hosts } from '../../api/hosts';

import * as types from "../../redux/Admin/admin.types";

import { terminalConcourseSelector } from "../../redux/Admin/admin.selectors";

export const setAdminData = (settings) => ({
  type: types.SET_ADMIN_DATA,
  settings,
});

// export const getAdminData = () => {
//   return async dispatch => {
//     const settings = hosts;
//     dispatch(setAdminData(settings));
//   };
// };

export const setAirportCode = (airportCode) => ({
  type: types.SET_AIRPORT_CODE,
  airportCode,
});

// export const setVenueName = venueName => ({
//   type: types.SET_VENUE_NAME,
//   venueName
// })

export const onAirportChange = (airport) => ({
  type: types.CHANGE_AIRPORT,
  airport,
});

export const onTerminalConcourseChange = (terminalConcourse) => ({
  type: types.CHANGE_TERMINAL_CONCOURSE,
  terminalConcourse,
});

export const clearTerminalConcourse = () => ({
  type: types.CLEAR_TERMINAL_CONCOURSE,
});

export const handleAirportChange = ({ value }) => {
  console.log("handleAirportChange input String: " + value);
  const hasSelectedTerminal = (options) => options !== null;
  return async (dispatch, getState) => {
    const state = await getState();
    const terminalConcourse = terminalConcourseSelector(state);
    if (hasSelectedTerminal(terminalConcourse)) {
      await dispatch(clearTerminalConcourse());
    }
    dispatch(onAirportChange(value));
  };
};

export const handleTerminalConcourseChange = ({ value }) => {
  console.log("handleTerminalChange input String: ", value);
  return (dispatch) => dispatch(onTerminalConcourseChange(value));
};

export const handleTestAction = ({ value }) => {
  console.log("handle test action value", value);
};
