import * as types from "./api.types";

const initialState = {
  apiCategories: {},
  apiItemModifierOptions: [],
  apiItems: [],
  apiModifierCollections: [],
  apiModifierGroups: [],
  categories: [],
  itemsByCategory: {},
  loading: true,
  loadingMessage: "Loading Categories...",
  apiError: false
};

const ApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_API_DATA:
      return {
        ...state,
        apiCategories: action.data.categories,
        apiItemModifierOptions: action.data.modifierOptions,
        apiItems: action.data.items,
        apiModifierCollections: action.data.modifierCollections,
        apiModifierGroups: action.data.modifierGroups,
        categories: action.data.categoriesArray,
        itemsByCategory: action.data.itemsByCategory
      };
    case types.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case types.SET_CATEGORY_DATA:
      return {
        ...state,
        categories: action.categories
      };
    case types.UPDATE_MENU_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.loadingMessage
      };
    case types.SET_API_ERROR:
      return {
        ...state,
        apiError: action.apiError
      };
    default:
      return state;
  }
};

export default ApiReducer;
