import React, { memo } from "react";

const groupSelString = {
  marginBottom: 8,
  color: "#363636",
  fontFamily: "Gibson-Regular",
  fontSize: "12px",
  fontWeight: "normal",
  letterSpacing: "0.4px",
  paddingLeft: 13,
  paddingRight: 13
};

const GroupSelectionString = memo(function GroupSelectionString({
  selectString
}) {
  if (selectString) {
    return <div style={groupSelString}>{selectString}</div>;
  }
});

export default GroupSelectionString;
