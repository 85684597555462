import { createSelector } from "reselect";

export const adminSettingsSelector = (state) => state.admin.settings;
export const airportGroupSelector = (state) => state.admin.airportGroup;
export const airportSelector = (state) => state.admin.airport;
export const airportCodeSelector = (state) => state.admin.airportCode;
export const secondaryHostSelector = (state) => state.admin.secondaryHost;
export const terminalConcourseSelector = (state) =>
  state.admin.terminalConcourse;
export const airportTitleObjSelector = (state) => state.admin.airportTitleObj;

export const restaurantNameSelector = createSelector(
  airportTitleObjSelector,
  airportCodeSelector,
  (airportTitleObj, airportCode) => {
    return `${airportTitleObj[airportCode].restaurantName}`;
  }
);

export const airportOptionsSelector = createSelector(
  adminSettingsSelector,
  airportGroupSelector,
  (settings, airportGroup) => {
    if (settings && airportGroup) {
      const airports = Object.keys(settings[airportGroup]);
      const options = [];
      airports.map((airport) =>
        options.push({ value: airport, label: airport })
      );
      return options;
    } else {
      return null;
    }
  }
);

export const terminalConcourseOptionsSelector = createSelector(
  adminSettingsSelector,
  airportGroupSelector,
  airportSelector,
  (settings, airportGroup, airport) => {
    if (settings && airportGroup && airport) {
      const terminalsConcourses = Object.keys(settings[airportGroup][airport]);
      const options = [];
      terminalsConcourses.map((terminalConcourse) =>
        options.push({ value: terminalConcourse, label: terminalConcourse })
      );
      return options;
    } else {
      return null;
    }
  }
);

export const getairportCodeSelector = createSelector(
  adminSettingsSelector,
  airportGroupSelector,
  airportSelector,
  terminalConcourseSelector,
  (settings, airportGroup, airport, terminalConcourse) => {
    if (settings && airportGroup && airport && terminalConcourse) {
      return settings[airportGroup][airport][terminalConcourse];
    } else {
      return null;
    }
  }
);
