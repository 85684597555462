import styled from "styled-components";

export const minus = require("./images/ui-minus-44-x-44-blue.png");
export const plus = require("./images/ui-plus-44-x-44-blue.png");

export const Container = styled.div`
  align-items: center;
  display: flex;
  // flex: 0.3;
  flex-direction: row;
  // margin-top: 13px;
  // margin-left: 13px;
  // margin-bottom: 20px;
  height: 40px;
  width: 188px;
  border-radius: 4px;
  border: 1px solid rgb(175, 175, 175);
  justify-content: space-evenly;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 13px;
`;

export const QuantityLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Gibson-Regular;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.4px;
  color: rgb(54, 54, 54);
`;

export const IncrementButton = styled.img.attrs(props => ({
  isLastUnit: props.isLastUnit,
  src: props.src
}))`
  filter: ${props => (props.isLastUnit ? "grayscale(100%)" : "none")};
  height: 18px;
  // margin-right: 25px;
  outline: none;
  width: 16px;
`;

export const QuantityButton = styled.div.attrs(props => ({
  isLastUnit: props.isLastUnit
}))`
  color: ${props => (props.isLastUnit ? "#afafaf" : "#545454")};
  height: 18px;
  // margin-right: 25px;
  outline: none;
  width: 16px;
  font-size: 18px;
  font-weight: solid;
`;

export const QuantityText = styled.div`
  color: rgb(84, 84, 84);
  font-family: Gibson-Medium;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  text-align: center;
  width: 33px;
`;
