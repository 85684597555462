import { Icon } from "semantic-ui-react";
import React, { memo } from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  text-align: center;
  width: 100%;
`;

const RestartButton = styled.button`
  background-color: #f44336;
  border: none;
  color: white;
  display: inline-block;
  font-size: 16px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
`;

const StackTrace = styled.div``;

const ErrorMessage = memo(function ErrorMessage({ componentStack, error }) {
  return (
    <Container>
      <h1>Oops! An error occured!</h1>
      <h2>
        Our engineers have been notified and will be correcting the error.
      </h2>
      <h2>
        <strong>Please restart the application.</strong>
      </h2>
      <RestartButton onClick={() => window.location.reload()}>
        <Icon name="refresh" />
        Restart Application
      </RestartButton>
      <StackTrace>
        {process.env.NODE_ENV === "development" && (
          <DebugInfo componentStack={componentStack} error={error} />
        )}
      </StackTrace>
    </Container>
  );
});

const DebugInfo = memo(function DebugInfo({ componentStack, error }) {
  return (
    <div>
      <h2>Component Stack</h2>
      <h3>{error.message}</h3>
      <p>{componentStack}</p>
    </div>
  );
});

export default ErrorMessage;
