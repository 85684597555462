import PreventDoublePressHoc from "../../../../organism/helpers/PreventDoublePress";
import { TouchableOpacity } from "react-native";
import checked from "../../assets/check-circle@3x.svg";
import disabled from "../../assets/ui-checkmark-rest.png";
import enabled from "../../assets/radio-circle@3x.svg";

export const SafeTouchableOpacity = PreventDoublePressHoc(TouchableOpacity);

const canAddGroupOption = (count, max) => {
  return count < max;
};

const canRemoveGroupOption = (count, min) => {
  return count > min || min === 0;
};

export const enforceMaxAndMinSelections = ({
  optionId,
  groupId,
  groupItemCount,
  groupIndex,
  maximumSelections,
  minimumSelections,
  optionIndex,
  isSelected,
  selectedItem,
  toggleCheckBox
}) => {
  if (isSelected) {
    if (canRemoveGroupOption(groupItemCount, minimumSelections)) {
      toggleCheckBox({
        optionId,
        groupId,
        groupIndex,
        optionIndex,
        isSelected,
        selectedItem
      });
    }
  } else {
    if (canAddGroupOption(groupItemCount, maximumSelections)) {
      toggleCheckBox({
        optionId,
        groupId,
        groupIndex,
        optionIndex,
        isSelected,
        selectedItem
      });
    }
  }
};

export const isCheckboxSelected = (selectedChecks, optionId) =>
  selectedChecks.includes(optionId);

export const checkboxSrcImage = isSelected => (isSelected ? checked : disabled);

export const buttonImageSrc = isSelected => (isSelected ? enabled : disabled);

export const ButtonHotSpot = {
  height: 48,
  width: 48,
  borderRadius: "50%",
  justifyContent: "center",
  alignContent: "center"
};
