import * as types from "./admin.types";

const initialState = {
  airport: null,
  airportGroup: "The Port Authority of New York and New Jersey",
  airportCode: "",
  settings: {
    "The Port Authority of New York and New Jersey": {
      "LaGuardia Airport (LGA)": {
        "Terminal C": "lga-c",
        "Terminal D": "lga-d"
      },
      "John F. Kennedy International Airport (JFK)": {
        "Terminal 2": "jfk-t2",
        "Terminal 5": "jfk-t5"
      },
      "Newark Liberty International Airport (EWR)": {
        "Concourse 1 (Gates 70-99)": "ewr-c1",
        "Concourse 2 (Gates 101-115)": "ewr-c2",
        "Concourse 3 (Gates 120-139)": "ewr-c3"
      }
    }
  },
  airportTitleObj: {
    "lga-c": {
      name: "LaGuardia Airport (LGA)",
      terminalConcourse: "Terminal C",
      restaurantName: "Cotto "
    },
    "lga-d": {
      name: "LaGuardia Airport (LGA)",
      terminalConcourse: "Terminal D",
      restaurantName: "Bisoux"
    },
    "ewr-c1": {
      name: "Newark Liberty International Airport (EWR)",
      terminalConcourse: "Concourse 1",
      restaurantName: "Abruzzo"
    },
    "ewr-c2": {
      name: "Newark Liberty International Airport (EWR)",
      terminalConcourse: "Concourse 2",
      restaurantName: "Proof"
    },
    "ewr-c3": {
      name: "Newark Liberty International Airport (EWR)",
      terminalConcourse: "Concourse 3",
      restaurantName: "Saison"
    },
    "jfk-t2": {
      name: "John F. Kennedy International Airport (JFK)",
      terminalConcourse: "Terminal 2",
      restaurantName: "Croque Madame"
    },
    "jfk-t5": {
      name: "John F. Kennedy International Airport (JFK)",
      terminalConcourse: "Terminal 5",
      restaurantName: "Loft"
    }
  },
  terminalConcourse: null
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ADMIN_DATA:
      return {
        ...state,
        settings: action.settings
      };
    case types.SET_AIRPORT_CODE:
      return {
        ...state,
        airportCode: action.airportCode
      };
    case types.CHANGE_AIRPORT:
      return {
        ...state,
        airport: action.airport
      };
    case types.CHANGE_TERMINAL_CONCOURSE:
      return {
        ...state,
        terminalConcourse: action.terminalConcourse
      };
    case types.CLEAR_TERMINAL_CONCOURSE:
      return {
        ...state,
        terminalConcourse: null
      };
    case types.CLEAR_RESTAURANT:
      return {
        ...state,
        airport: null,
        terminalConcourse: null
      };
    default:
      return state;
  }
};

export default AdminReducer;
