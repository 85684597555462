import * as types from "../../redux/Selected/selected.types";
import { toggleModalOpen } from "../Login/login.actions";
import { spacesToDashes } from "../../common/common";

export const setSelectedCartIndex = selectedCartIndex => ({
  type: types.SET_SELECTED_CART_INDEX,
  selectedCartIndex
});

export const setSelectedCategory = selectedCategory => ({
  type: types.SET_SELECTED_CATEGORY,
  selectedCategory
});

export const handleNavItemClick = (category, history) => {
  return dispatch => {
    const { title } = category;
    const displayCategory = title.trim();
    const route = `/category/${spacesToDashes(title)}`;
    dispatch(setSelectedCategory(displayCategory));
    history.push(route);
    dispatch(toggleModalOpen(false));
  };
};
