import "./custom-modal.css";

import {
  ButtonContainer,
  Column,
  Container,
  ContentContainer,
  Row
} from "./ItemModal.styles";
import React, { memo } from "react";
import {
  deviceSelector,
  itemModalOpenSelector,
  selectedChecksSelector,
  selectedIdSelector,
  selectedItemSelector,
  selectedItemWithModifiersTotal,
  selectedRadiosSelector
} from "../redux/selectors";
import {
  getPriceOrMiles,
  hasModifiers,
  hasSelectedItem
} from "./ItemModal.logic";

// import CancelButton from "../atoms/CancelButton";
import Description from "../atoms/Description";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemImage from "../atoms/ItemImage";
import ItemQuantity from "../atoms/ItemQuantity/ItemQuantity";
import Modal from "react-responsive-modal";
import Modifiers from "../molecules/Modifiers/organism/Modifiers";
import Separator from "../atoms/Separator";
import UpdateOrAddButton from "../atoms/UpdateOrAddButton/UpdateOrAddButton";
import { cancelAddToCart } from "../redux/actions";
import { cartItemIndexSelector } from "../redux/selectors";
import { connect } from "react-redux";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const animationDuration = 0;

const buttonContainerStyle = { marginBottom: 15 };
// const centered = true;

// const phoneView = {
//   display: "block",
//   position: "relative",
//   height: "150px"
// };

// const notPhoneView = {
//   display: "block",
//   position: "relative",
//   height: "auto"
// };

const styles = {
  screenContainer: {
    margin: 0,
    padding: 0,
    display: "block"
  },
  backToMenuButton: {
    height: "44px",
    color: "#868686",
    fontFamily: "Gibson-Regular",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "0.4px",
    padding: 0,
    marginLeft: "13px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  chevronLeft: {
    marginRight: "10px"
  }
};

const ItemModal = memo(function ItemModal({
  addItemToCart,
  calculatedPrice,
  cancelAddToCart,
  cartItemIndex,
  cmsIp,
  // device,
  itemModalOpen,
  modifierOptions,
  paymentType,
  selectedItem,
  translations,
  updateCartItem
}) {
  if (hasSelectedItem(selectedItem)) {
    const {
      description,
      imageUrl,
      menuItemId,
      itemType,
      modifierGroups,
      title
    } = selectedItem;
    const selectedItemId = menuItemId;
    const hasModifiersResult = hasModifiers(modifierGroups);
    const { modalClassNames, onClose, price } = {
      modalClassNames: {
        modal: hasModifiersResult ? "custom-modal-modifiers" : "custom-modal",
        overlay: "custom-overlay"
      },
      onClose: () => cancelAddToCart(),
      price: getPriceOrMiles(paymentType, calculatedPrice)
    };
    return (
      <div style={styles.screenContainer}>
        <Modal
          animationDuration={animationDuration}
          classNames={modalClassNames}
          onClose={onClose}
          open={itemModalOpen}
          center={true}
          showCloseIcon={false}
        >
          {itemModalOpen ? (
            <Container hasModifiers={hasModifiersResult}>
              <ContentContainer hasModifiers={hasModifiersResult}>
                <Row hasModifiers={hasModifiersResult}>
                  <ButtonContainer
                    onClick={onClose}
                    style={styles.backToMenuButton}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      style={styles.chevronLeft}
                    />
                    Back to Menu
                  </ButtonContainer>
                  <Column hasModifiers={hasModifiersResult}>
                    <ItemImage
                      cmsIp={cmsIp}
                      hasModifiers={hasModifiersResult}
                      imageUrl={imageUrl}
                      price={price}
                      title={title}
                    />
                    <Description
                      both
                      description={description}
                      hasModifiers={hasModifiersResult}
                      showCondition={false}
                    />
                    <ItemQuantity />
                  </Column>
                  {hasModifiersResult ? (
                    <Column hasModifiers={hasModifiersResult}>
                      <Modifiers
                        description={description}
                        hasModifiers={hasModifiersResult}
                        modifierGroups={modifierGroups}
                        modifierOptions={modifierOptions}
                        paymentType={paymentType}
                        selectedItem={selectedItem}
                      />
                    </Column>
                  ) : null}
                  <Separator
                    both
                    hasModifiers={hasModifiersResult}
                    showCondition="any"
                  />
                  <ButtonContainer style={buttonContainerStyle}>
                    <UpdateOrAddButton
                      addItemToCart={addItemToCart}
                      cartItemIndex={cartItemIndex}
                      itemType={itemType}
                      selectedItem={selectedItem}
                      selectedItemId={selectedItemId}
                      translations={translations}
                      updateCartItem={updateCartItem}
                      price={price}
                    />
                  </ButtonContainer>
                </Row>
              </ContentContainer>
            </Container>
          ) : null}
        </Modal>
      </div>
    );
  } else {
    return null;
  }
});

// {device === "phone" ? (
//   <div style={phoneView}>
//     <Separator
//       both
//       hasModifiers={hasModifiersResult}
//       showCondition="any"
//     />
//     <ButtonContainer style={buttonContainerStyle}>
//       <UpdateOrAddButton
//         addItemToCart={addItemToCart}
//         cartItemIndex={cartItemIndex}
//         itemType={itemType}
//         selectedItem={selectedItem}
//         selectedItemId={selectedItemId}
//         translations={translations}
//         updateCartItem={updateCartItem}
//         price={price}
//       />
//     </ButtonContainer>
//   </div>
// ) : null}

// {device !== "phone" && (
//   <div style={notPhoneView}>
//     <Separator
//       both
//       hasModifiers={hasModifiersResult}
//       showOCondition="any"
//     />
//     <ButtonContainer>
//       <UpdateOrAddButton
//         addItemToCart={addItemToCart}
//         itemType={itemType}
//         selectedItemId={selectedItemId}
//         translations={translations}
//         updateCartItem={updateCartItem}
//         cartItemIndex={cartItemIndex}
//         selectedItem={selectedItem}
//         price={price}
//       />
//     </ButtonContainer>
//   </div>
// )}

const mapStateToProps = (state, props) => {
  return {
    cartItemIndex: cartItemIndexSelector(state),
    calculatedPrice: selectedItemWithModifiersTotal(state, props),
    device: deviceSelector(state),
    itemModalOpen: itemModalOpenSelector(state),
    selectedChecks: selectedChecksSelector(state),
    selectedItem: selectedItemSelector(state),
    selectedItemId: selectedIdSelector(state),
    selectedRadios: selectedRadiosSelector(state)
  };
};

export default connect(
  mapStateToProps,
  { cancelAddToCart }
)(ItemModal);
