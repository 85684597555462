import React, { memo } from "react";

import ItemModal from "./organism/ItemModal";

export { toggleItemModal } from "./redux/actions";
export { itemModalOpenSelector } from "./redux/selectors";
export * from "./redux/reducer";

const ItemModalContainer = memo(function ItemModalContainer({
  addItemToCart,
  cmsIp,
  modifierOptions,
  paymentType,
  selectedItem,
  translations,
  updateCartItem
}) {
  return (
    <ItemModal
      addItemToCart={addItemToCart}
      cmsIp={cmsIp}
      modifierOptions={modifierOptions}
      paymentType={paymentType}
      selectedItem={selectedItem}
      translations={translations}
      updateCartItem={updateCartItem}
    />
  );
});

export default ItemModalContainer;
