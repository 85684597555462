import * as types from "./payeezy.types";

const initialState = {
  amount: null,
  cardNumber: null,
  email: null,
  errorMessage: null,
  firstName: null,
  isTransactionOpen: false,
  lastName: null,
  loading: false,
  redirectUrl: null,
  type: null,
  xInvoiceNum: "",
  xResponseCode: null,
  xResponseReasonText: null,
  xTransId: null
};

const PayeezyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.redirectUrl
      };
    case types.SET_INVOICE_NUM:
      return {
        ...state,
        xInvoiceNum: action.xInvoiceNum
      };
    case types.TOGGLE_PAY_BUTTON_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case types.TOGGLE_OPEN_TRANSACTION:
      return {
        ...state,
        isTransactionOpen: action.isTransactionOpen
      };
    case types.POST_CART_TO_PAPI:
      return {
        ...state
      };
    case types.SET_PAYEEZY_RESPONSE_DATA:
      return {
        ...state,
        amount: action.transaction.amount,
        cardNumber: action.transaction.cardNumber,
        email: action.transaction.email,
        expiry: action.transaction.expiry,
        firstName: action.transaction.firstName,
        lastName: action.transaction.lastName,
        type: action.transaction.type,
        xResponseCode: action.transaction.xResponseCode,
        xResponseReasonText: action.transaction.xResponseReasonText,
        xTransId: action.transaction.xTransId
      };
    case types.UPDATE_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message
      };
    default:
      return state;
  }
};

export default PayeezyReducer;
