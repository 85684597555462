import { Platform } from "react-native";

export const hasSelectedItem = selectedItem => selectedItem !== null;
export const hasModifiers = groups => groups && groups.length > 0;
export const isPortrait = orientation => orientation === "portrait";
export const isLandscape = orientation => orientation === "landscape";
const isWeb = Platform => Platform.OS === "web";
const isMiles = ({ paymentType }) => paymentType === "MILES";

// This below will break in react-native.

// react-responsive-modal does not double react-native's modal
// refactor ItemModal's content into it's own component and create
// a web component ItemModal.web.js with react-responsive-modal's syntax
// create another file ItemModal.native.js with react-native's syntax
// put ItemModalContent.js inside each Modal in each file

export const Modal = isWeb(Platform)
  ? require("react-responsive-modal")
  : require("react-native").Modal;

export const getPriceOrMiles = (paymentType, calculatedPrice) => {
  return isMiles({ paymentType })
    ? `${calculatedPrice} miles`
    : `$${calculatedPrice}`;
};
