// const lightGrey = "#d1d1d1";
// const iosGrey = "#f7f7f7";

const styles = {
  optionContainer: {
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#eaeaea",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 7,
    paddingTop: 7,
    paddingLeft: 13,
    paddingRight: 13
  },
  optionPriceContainer: {
    alignItems: "center",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingBottom: 7,
    paddingTop: 7
  },
  optionText: {
    color: "#545454",
    fontSize: "14px",
    fontFamily: "Gibson-Regular",
    fontWeight: "normal",
    letterSpacing: "0.4px"
  }
};

export default styles;
