export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export const CLEAR_RESTAURANT = "CLEAR_RESTAURANT";
export const INCREMENT_CART_QUANTITY = "INCREMENT_CART_QUANTITY";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const REMOVE_MODIFIER_FROM_CART_ITEM = "REMOVE_MODIFIER_FROM_CART_ITEM";
export const SET_CART_QUANTITY = "SET_CART_QUANTITY";
export const SET_CART_TOTAL = "SET_CART_TOTAL";
export const TOGGLE_CART_TOTAL_LOADING = "TOGGLE_CART_TOTAL_LOADING";
export const TOGGLE_ITEM_MODAL = "TOGGLE_ITEM_MODAL";
export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_SELECTED_UNIQUE_ID = "UPDATE_SELECTED_UNIQUE_ID";
export const SET_TIP_RATE = "SET_TIP_RATE";
export const SET_CUSTOM_TIP_AMOUNT = "SET_CUSTOM_TIP_AMOUNT";
