import styled from "styled-components";

const style = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 32,
    width: 32,
    marginLeft: 5,
    marginRight: 13
  },
  checkImage: {
    height: 32,
    width: 32
  },

  radioButton: {
    marginTop: 2,
    marginRight: 10,
    height: 32,
    width: 32
  }
};

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-left: 13px;
  margin-right: 13px;
  width: 24px;
`;

export const Checkbox = styled.img.attrs(props => ({
  src: props.src
}))`
  margin: 12px;
  height: 24px;
  width: 24px;
`;

export const Radio = styled.img.attrs(props => ({
  src: props.src
}))`
  height: 24px;
  width: 24px;
  margin: 12px;
`;

export default style;
