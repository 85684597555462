import React from "react";
// import Separator from "./Separator";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ItemImageHeader = styled.img.attrs(props => ({
  src: props.src,
  hasModifiers: props.hasModifiers
}))`
  max-height: 250px;
  height: auto;
  object-fit: cover;
  @media (max-width: 500px) {
    height: ${window.innerWidth - 25}px;
    width: ${window.innerWidth}px;
  }
  @media (min-width: 501px) and (max-width: 725px) {
    height: auto;
    width: 18.7rem;
    margin-top: 10px;
  }
  @media (min-width: 726px) {
    height: auto;
    margin-top: 10px;
    max-width: 375px;
    width: 23rem;
  }
`;

const TitlePriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
`;
const Title = styled.div`
  font-family: Gibson-Medium;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 16px;
  margin-top: 0px;
  width: ${window.innerWidth}px;
  color: #363636;
`;

const Price = styled.div`
  font-family: Gibson-Regular;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 13px;
  margin-left: 13px;
  margin-right: 16px;
  text-align: right;
  width: ${window.innerWidth}px;
  color: #363636;
`;

const ItemImage = ({ cmsIp, imageUrl, hasModifiers, price, title }) => {
  return (
    <Container>
      <ItemImageHeader hasModifiers={hasModifiers} src={cmsIp + imageUrl} />
      <TitlePriceDiv>
        <Title>{title}</Title>
        <Price>{price}</Price>
      </TitlePriceDiv>
    </Container>
  );
};

// <Separator hasModifiers={hasModifiers} showCondition={false} both />

export default ItemImage;
