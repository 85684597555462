import {
  ButtonHotSpot,
  SafeTouchableOpacity,
  buttonImageSrc
} from "./Common/Common.logic";
import { Container, Radio } from "./Common/Common.style";
import React, { memo } from "react";
import {
  isRadioSelectedSelector,
  selectedRadiosSelector
} from "../../../redux/selectors";

import { connect } from "react-redux";
import { toggleRadioButton } from "../../../redux/actions";

const RadioButton = memo(function RadioButton({
  groupId,
  isSelected,
  optionId,
  selectedItem,
  selectedRadios,
  toggleRadioButton
}) {
  return (
    <Container>
      <SafeTouchableOpacity
        onPress={() =>
          toggleRadioButton({
            groupId,
            isSelected,
            optionId,
            selectedItem,
            selectedRadios
          })
        }
        style={ButtonHotSpot}
      >
        <Radio src={buttonImageSrc(isSelected)} />
      </SafeTouchableOpacity>
    </Container>
  );
});

const mapStateToProps = (state, props) => ({
  selectedRadios: selectedRadiosSelector(state),
  isSelected: isRadioSelectedSelector(state, props)
});

export default connect(
  mapStateToProps,
  { toggleRadioButton }
)(RadioButton);
